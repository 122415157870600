var DS = window.DS || {};
DS.roomsGuestsSelect = function() {
  var els = {};
  var vars = {};
  var stepperVal = 0;
  var stepperValRooms = 1;

  var GUESTS = {
     totalGuest: 6,
   availability: 5,
       minAdult: 1,
       maxAdult: 6,
    minChildren: 0,
    maxChildren: 5,
    maxChildAge: 17,
     adultCount: 1,
  childrenCount: 0,
      maxRooms: 26,
      minRooms: 1
  };

  var listen = function() {
    els.component.siblings().find('input, button').on({
      focus: function() {
        if ( !els.roomsGuestsPanel.hasClass('is-hidden') ) {
          onRoomsGuestsClicked();
        }
      }
    });

    els.component.parents('.booking-component').on({
      click: function(evt) {
        evt.stopPropagation();
        var isRoomsGuestTrigger = $(evt.target).is(els.roomsGuestsTrigger);
        var clickedOutsideOfRoomsGuests = !vars.isMobile && !isRoomsGuestTrigger && els.roomsGuestsTrigger.has(evt.target).length === 0 && els.roomsGuestsPanel.has(evt.target).length === 0 && els.roomsGuestsPanel.is(":visible");

        // check if the clicked area is room guest dropdown or not
        // close room guest dropdown when clicked outside
        if (clickedOutsideOfRoomsGuests) {
          closeRoomsGuests();
        }
      }
    });

    els.bookingComponent.on({
      focus: function() {
        $(this).select();
      },
      change: function() {
        parseStepperValue( $(this) );
      }
    }, '.stepper-input');

    els.roomsGuestsTrigger.on({
      focus: function(evt) {
        evt.preventDefault();
        if ( !vars.isMobile ) {
          $(this).trigger('mousedown');
        }
      },
      mousedown: function(evt) {
        evt.preventDefault();
        if ( !vars.isMobile ) {
          onRoomsGuestsClicked();
        }
      }
    });

    els.component.on({
      click: stepperClickHandler
    }, '.plus, .minus');

    els.resetRoomsGuests.on({
      click: reset
    });

    els.numOfFlexNightsInput.on({
      parseStepperValue: function(evt, val) {
        parseStepperValue($(evt.target), val);
      }
    });
  };

  var parseStepperValue = function( $this, val, event ) {
    var rooms = $this.hasClass('js-rooms-num');
    var adults = $this.hasClass('js-adults-num');
    var age = $this.hasClass('js-child-age');
    var nights = $this.hasClass('js-night-num');
    // var children = $this.hasClass('js-children-num');
    var min = parseInt($this.data('min'));
    var max = parseInt($this.data('max'));
    var string, selectOption;
    var getNumber = $this.val().match(/^0$|^-?[1-9]\d*(\.\d+)?$/);
    var number = val || val === 0 || age ? val : getNumber !== null ? parseInt(getNumber[0]): 1; // Get initial value from input string

    if (number < min) {
      number = min;
    }
    if (number > max) {
      number = max;
    }

    if ( rooms ) {
      if ( number >= GUESTS.maxRooms || val === 6  ) {
        number = "26+";
        val = 26;
        stepperValRooms = 6;
        string = $this.data( 'plural' );
      }
      else if ( number >= 10 || val === 5 ) {
        number = "10-25";
        val = 10;
          stepperValRooms = 5;
        hideAdultsChildrenFields();
        string = $this.data( 'plural' );
      }
      else if ( number >= 4 || val === 4 ) {
        number = "4-9";
        val = 4;
        stepperValRooms = 4;
        showAdultsChildrenFields();
        string = $this.data( 'plural' );
      }
      else {
        showAdultsChildrenFields();
        val = number;
        string = $this.data( number > 1 ? 'plural' : 'singular' );
      }

      if (number < GUESTS.minRooms) {
        number = 1;
        val = 1;
        stepperValRooms = 1;
      }
      selectOption = $this.parent().find('.roomCount option[value="'+val+'"]');
      selectOption.prop('selected', true);
      updateRoomsGuestsFieldString('rooms', number);
      $this.val( number +' '+ string );
    }
    else if ( adults ) {
      if ( (number + GUESTS.childrenCount) > GUESTS.totalGuest ) {
          number = GUESTS.totalGuest - GUESTS.childrenCount;
      }
      if (number < GUESTS.minAdult) {
          number = GUESTS.minAdult;
      }

      selectOption = $this.parent().find('.guestCount option[value="'+number+'"]');
      selectOption.prop('selected', true);
      string = getRoomsGuestsSingularPluralLabel( $this, number );
      updateRoomsGuestsFieldString('adults', number);
      $this.val( number +' '+ string );
      GUESTS.adultCount = number;
    }
    else if ( age ) {
      var _val;

      if (typeof event !== 'undefined' && event.type === 'change') {
          _val = parseInt(getNumber);

          if (isNaN(_val)) {
            _val = 0;
          }
      }
      else {
          if (typeof val === "undefined" && getNumber) {
            _val = parseInt(getNumber[0]);
          } else {
            _val = val ? val : 0;
          }
      }

      if ( _val > parseInt($this.data('max')) ) {
          _val = parseInt($this.data('max'));
          stepperVal = _val;
      }
      else if (_val < parseInt($this.data('min'))) {
          _val = parseInt($this.data('min'));
          stepperVal = _val;
      }
      val = _val;
      updateChildrenAgesArray(null, $this, _val );
      selectOption = $this.parent().find('.childrenAge option').eq(_val);
      selectOption.prop('selected', true);
      $this.val( selectOption.text() );
      $this.attr("aria-label", selectOption.text());
    }
    else if ( nights ) {
      string = getRoomsGuestsSingularPluralLabel( $this, number );
      updateRoomsGuestsFieldString('nights', number);

      selectOption = $this.parent().find('.nightCount option').eq(number - 1);
      selectOption.prop('selected', true);

      $this.val( number +' '+ string );
    }
    // Children
    else {
      if ( (number + GUESTS.adultCount) > GUESTS.totalGuest ) {
        number = GUESTS.totalGuest - GUESTS.adultCount;
      }
      if (number < GUESTS.minChildren) {
          number = GUESTS.minChildren;
      }

      selectOption = $this.parent().find('.childrenCount option[value="'+number+'"]');
      selectOption.prop('selected', true);
      string = getRoomsGuestsSingularPluralLabel( $this, number, 'children' );
      updateRoomsGuestsFieldString('children', number);
      $this.val( number +' '+ string );
      GUESTS.childrenCount = number;

      vars.childrenAgesArray.splice(number);

      appendChildAge();
    }

    $this.closest('.acc-hidden').html( number +' '+ string ); // For voice over on input change
    $this.siblings('.stepper-num').text( number );
    // $this.siblings('select').val( val );
    toggleStepperButtons( $this, val, min, max );
  };

  var hideAdultsChildrenFields = function() {
    els.allGuests.hide();
    els.adultCountText.hide();
    els.childrenCountText.hide();
    resetGuestFields();
  };

  var showAdultsChildrenFields = function() {
    els.allGuests.show();
    els.adultCountText.show();
    els.childrenCountText.show();
  };

  var onRoomsGuestsClicked = function() {
    // var roomsGuestDropdownData = {
    //   inactive: 'is-inactive'
    // };
    els.roomsGuestsPanel.stop(true).slideToggle("fast")
      .attr('aria-expanded', els.roomsGuestsPanel.attr('aria-expanded') === 'false' ? 'true' : 'false')
      .toggleClass('is-hidden')
      .parents(".form-group")
      .find('.dropdown-trigger')
      .toggleClass('dropdown-is-active');
  };

  var closeRoomsGuests = function() {
    els.roomsGuestsPanel.slideUp('fast')
      .attr('aria-expanded','false')
      .addClass('is-hidden')
      .parents(".form-group")
      .find('.dropdown-trigger')
      .removeClass('dropdown-is-active');
  };

  var getRoomsGuestsSingularPluralLabel = function( $this, _val, _field ) {
    if( _field === 'children' ) {
      return $this.data( (_val > 1 || _val === 0) ? 'plural' : 'singular');
    }
    return $this.data( (_val > 1) ? 'plural' : 'singular' );
  };

  var stepperClickHandler = function(event, _this) {
    event.preventDefault();
    var $el = _this ? $(_this) : $(this);
    var input = $el.parent().find('input');
    var min = parseInt(input.data('min'));
    var max = parseInt(input.data('max'));
    var minus = $el.hasClass('js-minus');
    var plus = $el.hasClass('js-plus');

    if ( input.hasClass( 'js-night-num' ) ) {
        stepperVal = parseInt(input.val().match(/\d+/)[0]); // Get initial value from input string
    }

    if ( input.hasClass( 'js-rooms-num' ) ) {

        addRemoveRooms(input, max, min, plus, minus);
        // stepperValRooms = $el.parent().find('.roomCount').prop('selectedIndex');
        return false;
    }
    if ( input.hasClass( 'js-child-age' ) ) {
        stepperVal = $el.parent().find('.childrenAge').prop('selectedIndex');
        if (stepperVal < 0) {
            stepperVal = 0;
        }
        // setChildAge(input, max, min, plus, minus);
        (plus && stepperVal < max) ? stepperVal++ : (minus && stepperVal > min) ? stepperVal-- : false;
        parseStepperValue( input, stepperVal, event );
        return false;
    }

    // track total guests
    if (input.hasClass( 'js-children-num' ) || input.hasClass( 'js-adults-num' )) {

      if ( GUESTS.availability === 0 && plus ) {
        return false;
      }

      stepperVal = parseInt(input.val().match(/\d+/)[0]); // Get initial value from input string

      if (plus && GUESTS.availability > 0) {
          GUESTS.availability--
      } else if (minus && GUESTS.availability < GUESTS.totalGuest) {
          GUESTS.availability++
      }
    }

    if ( input.hasClass( 'js-children-num' ) ) {
        // Append age container per child
        if (plus && stepperVal < max) {
          // vars.childrenAgesArrayIndex += 1;
          // $('.age-container').eq(stepperVal).find('label .num').text(stepperVal + 1);
          // updateChildrenAgesArray('add');
        } else if (minus && stepperVal > min) {
          // vars.childrenAgesArrayIndex -= 1;
          updateChildrenAgesArray('remove');
        }
    }

    (plus && stepperVal < max) ? stepperVal++ : (minus && stepperVal > min) ? stepperVal-- : false;
    parseStepperValue( input, stepperVal );
  };

  var appendChildAge = function() {
    $('.age-container').remove();

    for (var i = 0; i < GUESTS.childrenCount; i++) {
        els.childrenAgesContainer.append('<div class="age-container">' + els.childAgeTemplate.html() + '</div>');
        $('.age-container').eq(i).find('label .num').text(i + 1);
    }

    $('.age-container').find('.js-child-age').each(function(index){
      parseStepperValue( $(this), vars.childrenAgesArray[index] );
    });
  };

  var addRemoveRooms = function( input, max, min, plus, minus ) {
      (plus && stepperValRooms < max) ? stepperValRooms++ : (minus && stepperValRooms > min) ? stepperValRooms-- : false;

      // els.roomCount.prop('selectedIndex', stepperValRooms ).change();
      parseStepperValue( input, stepperValRooms );
  };

  var setChildAge = function( _input, _max, _min, _plus, _minus  ) {
      // (_plus && stepperVal < _max) ? stepperVal++ : (_minus && stepperVal > _min) ? stepperVal-- : false;
      // parseStepperValue( _input, stepperVal );
  };

  var updateChildrenAgesArray = function( _action, _input, _val ) {
    var i;

    if ( _action ) {
        i = $('.age-container').length - 1;

        if ( _action === 'add' ) {
            vars.childrenAgesArray[i] = 0;
        }
        else {
            vars.childrenAgesArray.pop();
        }
    }
    else {
        i = _input.parents('.age-container').index();
        vars.childrenAgesArray[i] = _val;
    }

    els.childrenAges.val(vars.childrenAgesArray);
  };

  var updateRoomsGuestsFieldString = function( field, val ) {
    // Update the display string
    var textElem;
    var valueElem;

    if ( field === 'children' ) {
      textElem = '.js-children-text';
      valueElem = '.js-number-of-children';
    }
    else if ( field === 'adults' ) {
      textElem = '.js-adults-text';
      valueElem = '.js-number-of-adults';

    }
    else if ( field === 'nights') {
      textElem = '.js-nights-label-display';
      valueElem = '.js-nights-length-display';
    }
    else {
      textElem = '.js-rooms-text';
      valueElem = '.js-number-of-rooms';
    }

    if ( field === 'nights' ) {
        $('.js-nights-length-display').text(val);
        textElem = $(textElem);
    }
    else {
        els.roomsGuestsTrigger.find(valueElem).text(val);
        textElem = els.roomsGuestsTrigger.find(textElem);
    }

    textElem.html( getRoomsGuestsSingularPluralLabel( textElem, val, field === 'children' ? 'children':null ) );
  };

  var toggleStepperButtons = function( $el, val, min, max ) {
    var $el_minus = $el.siblings('.js-minus');
    var $el_plus = $el.siblings('.js-plus');

    $el_minus.removeClass('inactive').attr('aria-disabled', false);
    $el_plus.removeClass('inactive').attr('aria-disabled', false);

    if (val === max) {
      $el_plus.addClass('inactive').attr('aria-disabled', true);
    }
    else if (val === min) {
      $el_minus.addClass('inactive').attr('aria-disabled', true);
    }

    if (!els.plusGroup) return false;

    if ( GUESTS.availability === 0 ) {
      els.plusGroup.addClass('inactive').attr('aria-disabled', true);
    } else {
      els.plusGroup.removeClass('inactive').attr('aria-disabled', false);
    }
  };

  var reset = function(evt) {
    evt.preventDefault();
    GUESTS.availability = 5;
    stepperValRooms = 1;
    els.component.find('.age-container').remove();

    els.stepperInput.each(function(){
      var $this = $(this);
      var min = $this.data('min');

      parseStepperValue( $this, min );
      $this.siblings('.stepper-num').text( min );
      $this.siblings('select').val( min );
    });
  };

  var resetGuestFields = function() {
    GUESTS.availability = 5;

    var guestInputs = els.allGuests.find('.stepper-input');

    guestInputs.each(function() {
      var $this = $(this);
      var min = $this.data('min');

      parseStepperValue( $this, min );
      $this.siblings('.stepper-num').text( min );
      $this.siblings('select').val( min );
    });
  };

  return {
    init: function(el) {
      var $el = $(el);

      els = {
        component: $el,
        bookingComponent: $el.parents('.advanced.booking-component'),
        formGroup: $el.find('.form-group'),
        plusMinus: $el.find('.plus, .minus'),
        plusGroup: $el.find('.group-plus'),
        roomsGuestsTrigger: $el.find('.js-rooms-guests-trigger'),
        roomsGuestsPanel: $el.find('.js-rooms-guests-panel'),
        roomsGuestsSelect: $el.find('.rooms-guests-select'),
        stepperInput: $el.find('.stepper-input'),
        resetRoomsGuests: $el.find('.reset-rooms-guests'),
        screenReaderEl: $el.find('.acc-hidden'),
        roomCount: $el.find('.roomCount'),
        allGuests: $el.find('.js-all-guests'),
        adultCountText: $el.find('.js-adlt'),
        childrenCountText: $el.find('.js-chld'),
        childrenAgesContainer: $el.find('.js-children-ages-container'),
        childrenAges: $el.find('.js-children-ages'),
        childAgeTemplate: $el.find('.child-age-template'),
        numOfFlexNightsInput: $el.parents('.advanced.booking-component').find('#numberOfNights-input')
      };
      vars = {
        isMobile: window.app.isMobileWindowSize(),
        childrenAgesArray: [],
        childrenAgesArrayIndex: -1
      };
      resetGuestFields();
      listen();
    },
    stepperClickHandler: stepperClickHandler,
    parseStepperValue: parseStepperValue
  };
};

$(function() {
  var $components = $('.rooms-guests-select');
  if ( $components.length ) {
    $components.each(function(i,el) {
      var roomsGuestsSelect = new DS.roomsGuestsSelect();
      roomsGuestsSelect.init(el);
    });
  }
});
