var DS = window.DS || {};
DS.modalGeneric = function() {
    var els = {};
    var vars = {};

    var listen = function() {
        els.genericLink.on({
            click: function(e){
                e.preventDefault();
                var $this = $(this);
                els.curgeneric = $this;

                openModal($this);
            }
        });

        els.closeModal.on({
            click: function(e){
                closeModal();
            }
        });

        els.body.on({
            keyup: function(e) {
                if (e.keyCode === 27) closeModal();   // esc
            }
        });
    };

    var openModal = function($this) {
        vars.isOpen = true;

        var drinkData = $this.data('modalHtml');
        els.modalContent.empty().append(decodeURIComponent($this.data('modalHtml')));

        els.component.removeClass( 'close' );
        els.body.addClass( 'modal-open' );
        els.component.addClass( 'open' );

        // Set focus to close button for accessibility
        els.closeModal.focus();
    };

    var closeModal = function() {
      if (vars.isOpen) {
        els.component.addClass( 'close' );
        els.body.removeClass( 'modal-open' );
        els.component.removeClass( 'open' );
      }
    };

    return {
        init: function(el) {
            var $el = $(el);

            els = {
                component: $el,
                genericComponent: $( '.generic-modal' ),
                modalContent: $el.find('.modal-content'),
                genericLink: $( '.link[data-modal-html]' ),
                body: $( 'body' ),
                closeModal: $el.find( '.close-modal' ),
                modal: $el.find( '.modal' )
            };

            vars = {
                isOpen: false
            };

            listen();
        }
    };
};

$(function() {
    var $components = $( '.generic-modal' );

    if ( $components.length > 0 ) {
        $components.each(function(i,el) {
            var modal = new DS.modalGeneric();
            modal.init(el);
        });
    }
});
