var DS = window.DS || {};
var motionQuery = matchMedia("(prefers-reduced-motion)");

DS.carousel = function () {
	var els = {};
	var isRTL = document.dir == 'rtl';

	var listen = function () {
		if (window.app.isMobile())
			$(window).resize(repositionSlickDots);
		els.component.on('keyup', '.carousel-arrow', onArrowKeyup);
	};

	var onArrowKeyup = function (e) {
		if (e.keyCode === 13) {
			setTimeout(function () {
				var $currSlide = els.slides.find('.slide.slick-current');
				$currSlide.find('a').first().focus();
			}, 1000);
		}
	};

	var buildCarousel = function () {
		if ( isRTL ) { els.slides.attr('dir','rtl') };
		var initAutoplayVideos = function () {
			if (!window.app.isMobile()) {
				var $firstSlide = els.slides.find('.slide').first();
				if ($firstSlide.find('.background').hasClass('autoplay-video')) {
					if (motionQuery.matches) {
						videojs($firstSlide.find('.video-js').attr('id')).pause();
					} else {
						videojs($firstSlide.find('.video-js').attr('id')).play();
					}
				}
			}
		};

		var beforeChangeAction = function (e, slick, currentSlide, nextSlide) {
			var $currSlide = els.slides.find('.slide[data-slick-index="' + currentSlide.toString() + '"]');
			var $nextSlide = els.slides.find('.slide[data-slick-index="' + nextSlide.toString() + '"]');
			if (!window.app.isMobile()) {
				if ($currSlide.find('.background').hasClass('autoplay-video')) {
					videojs($currSlide.find('.video-js').attr('id')).pause();
				}
				if ($nextSlide.find('.background').hasClass('autoplay-video')) {
					if (!motionQuery.matches) {
						videojs($nextSlide.find('.video-js').attr('id')).play();
					}
				}
			}
		};

		var afterChangeAction = function (e, slick, currentSlide, nextSlide) {
			els.slideElements.each(function (index, element) {
				var $slide = $(element);
				var $slideLink = $slide.find('a');
				var isSlideHidden = $slide.attr("aria-hidden") === "true";
				if ($slideLink.length) $slideLink.attr('tabindex', isSlideHidden ? '-1': '0');
			});
		};

		var slidesToShow = 1;
		var slidesToShowDataAttribute = els.component.attr('data-slides-to-show');
		if (slidesToShowDataAttribute) {
			slidesToShow = parseInt(slidesToShowDataAttribute);
		}
		var infinite = false;
		if (els.component.attr('data-infinite')) {
			infinite = true;
		}

		var slickOptions = {
			arrows: true,
			infinite: infinite,
			nextArrow: '<button class="carousel-arrow carousel-arrow-next icon-carret" aria-label="' + els.nextLabel + '"></button>',
			prevArrow: '<button class="carousel-arrow carousel-arrow-prev icon-carret" aria-label="' + els.previousLabel + '"></button>',
			dots: true,
			slidesToShow: slidesToShow,
			slidesToScroll: 1,
			waitForAnimate: false,
			slide: '.slide',
			asNavFor: els.slideAccessory.length ? ('#' + els.slideAccessory.attr('id')) : null,
			accessibility: false,
			rtl: isRTL,
			responsive: [
				{
					breakpoint: 769,
					settings: {
						infinite: false,
						slidesToShow: 1,
						appendArrows: els.counterContainer
					}
				}
			]
		};

		//Multiblock Carousel Options

		if (els.component[0].classList.contains('multi-block')) {
			slickOptions.slidesToShow = parseFloat($(els.component[0]).attr('data-slides'));
			slickOptions.responsive[0].settings.slidesToShow = 1;
			slickOptions.responsive[0].settings.centerMode = true;
			slickOptions.responsive[0].settings.centerPadding = '80px';
			setTimeout(function () {
				$(els.component[0]).find('.svg-load').each(function () {
					var _this = $(this);
					svgURL = _this.attr('data-svg');
							$.get(svgURL, function (data) {
								$("svg", data).prependTo(_this);
							});
						});
			}, 0);
		}

		// hide dots if single slide
		if (els.slides.find('.slide').length < 2) {
			slickOptions.dots = false;
			els.counterContainer.remove();
		}

		els.component.find('img').on('load', function(){
			els.slides.slick('resize');
		});

		els.slides.on({
			init: afterChangeAction,
			beforeChange: beforeChangeAction,
			afterChange: afterChangeAction
		});

		els.slides.not('.slick-initialized').slick(slickOptions);

		// initAutoplayVideos();
		if (els.slideAccessory.length) buildAccessoryCarousel();

		els.counter.text('1/' + els.slideElements.length);
	};

	var buildAccessoryCarousel = function () {
		els.slideAccessory.slick({
			arrows: false,
			infinite: false,
			dots: false,
			waitForAnimate: false,
			fade: true,
			touchMove: false,
			swipe: false,
			accessibility: false,
			rtl: isRTL,
		});
	};

	var updateSlideCount = function () {
		els.slides.on('init reInit afterChange', function (event,slick, currentSlide, nextSlide) {
			var i = (currentSlide ? currentSlide : 0) + 1;
			els.counter.text(i + '/' + slick.slideCount);
		});
	};

	var repositionSlickDots = function () {
		var resize = false;
		var reposition = function () {
			if (resize) return;
			resize = true;
			requestAnimationFrame(function () {
				$('.slick-dots').each(function () {
					var imageBlock = $(this).parent().find('.image-block');
					var offsetY = (imageBlock.height() + 10);
					$(this).css({transform: 'translate(-50%,' + offsetY + 'px)'});
				});
				resize = false;
			});
		};
		reposition();
	};

	return {
		init: function (el,i) {
			var $el = $(el);
			els = {
				component: $el,
				slides : $el.find('.slides'),
				slideElements : $el.find('.slide'),
				counter : $el.find('.slide-count'),
				counterContainer: $el.find('.slide-count-container'),
				slideAccessory : $el.find('.slide-accessory'),
				slideHeadings: $el.find('.slide').find('.heading'),
				previousLabel: $el.data('previous-label'),
				nextLabel: $el.data('next-label')
			};
			if (els.slideAccessory.length) els.slideAccessory.attr('id','slide-accessory-' + i);
			updateSlideCount();
			buildCarousel();
			listen();

			if (window.app.isMobile()) {
				setTimeout(function () {
					repositionSlickDots();
				}, 300);
			}
		}
	};
};

$(function () {
	if ($('.carousel-component').length) {
		$('.carousel-component').each(function (i, el) {
			var carousel = new DS.carousel();
			carousel.init(el,i);
		});
	}
});
