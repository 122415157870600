var DS = window.DS || {};
DS.parallax = function() {
	var els = {};
	var vars = {};

	var listen = function() {
    $(window).scroll(function() {
      window.requestAnimationFrame(writeLayout);
    });

    function writeLayout() {
      updateConfigVars();
      simpleParallax();
    };
	};

	var simpleParallax = function () {
    var element = els.item;
    var startingPos = vars.startingPos;
    var scrollTop = vars.scrollTop;
		var rate = element.attr('data-parallax-rate') ? element.attr('data-parallax-rate') : vars.defaultRate;
    var scrollDiff = scrollTop + (vars.windowHeight * 0.333) - startingPos;
    var parallaxPos = scrollDiff / (rate*-1);

    if(element.attr('data-parallax-fixed')){
      parallaxPos = scrollTop / (rate*-1);
    }

    if(parallaxPos < 0 && element.attr('data-parallax-limit')){
      parallaxPos = 0;
    }
		element.css('transform', 'translate3d( 0, ' + parallaxPos + 'px, 0)');
  };

  var updateConfigVars = function(){
    vars.startingPos = els.item.offset().top;
    vars.scrollTop = $(window).scrollTop();
    vars.bodyHeight = $('body').height();
  };

	return {
		init: function(el) {

			var $el = $(el);

			els = {
        item: $el,
        siteBackground: $('.site-background')
			};

			vars = {
        defaultRate: 5,
        startingPos: $el.offset().top,
        scrollTop: $(window).scrollTop(),
        windowHeight: $(window).height(),
        bodyHeight: $('body').height(),
      };

      setTimeout(function(){
        updateConfigVars();
        simpleParallax();
      },1000);

			listen();
		}
	};
};

$(function() {
	var $items = $( '.parallax' );

	if ( $items.length > 0 ) {
			$items.each(function(i,el) {
        if (!window.app.isMobile()) {
					var parallax = new DS.parallax();
          parallax.init(el);
        }
      });
	}
});
