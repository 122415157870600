var DS = window.DS || {};
DS.eventCalendarPreview = function (el) {
  var els = {};

  var init = function () {
    els.component = $(el);
    els.loadMore = els.component.find('.event-calendar-preview__load-more');

    listen();
  };

  var listen = function () {
    els.loadMore.on('click', function (event) {
      els.component.toggleClass('event-calendar-preview--state-load-more');
    });
  };

  init();
};

$(function () {
  var $components = $('.event-calendar-preview');
  if (!$components.length) return;
  $components.each(function (i, el) {
    new DS.eventCalendarPreview(el);
  });
});
