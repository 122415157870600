var DS = window.DS || {};

function calculateCurrentValue(currentTime) {
  var current_minute = parseInt(currentTime / 60) % 60;
  var current_seconds_long = currentTime % 60;
  var current_seconds = current_seconds_long.toFixed();
  var current_time = (current_minute < 10 ? "0" + current_minute : current_minute) + ":" + (current_seconds < 10 ? "0" + current_seconds : current_seconds);
  return current_time;
}

DS.soundtrackYourBrand = function (el) {
  this.elements = {};
  this.elements.$element = $(el);
  this.elements.$regionSelect = this.elements.$element.find('.soundtrack-your-brand__region-select select');
  this.elements.$regionList = this.elements.$element.find('.soundtrack-your-brand__region-list');
  this.elements.$regions = this.elements.$element.find('.soundtrack-your-brand__region');
  this.elements.$properties = this.elements.$element.find('.soundtrack-your-brand__property');
  this.elements.$activeSoundtrack = this.elements.$element.find('.soundtrack-your-brand__active-soundtrack');
  this.elements.$close = this.elements.$element.find('.soundtrack-your-brand__close');
  this.elements.map = this.elements.$element.find('.soundtrack-your-brand__map')[0];
  this.map = null;
  this.properties = [];
  this.markers = [];
  this.inactiveIcon = null;
  this.activeIcon = null;
  this.componentData = this.elements.$element.data(),

  this.init();
  this.addListeners();
};

DS.soundtrackYourBrand.prototype.init = function () {
  this.elements.$properties.each(function (i, el) {
    var newProperty = new DS.soundtrackYourBrandProperty(el, this.setActiveProperty.bind(this));
    this.properties.push(newProperty);
  }.bind(this));

  $(window).on('googleMapsReady', this.initMap.bind(this));
  if (window.app.mapReady()) this.initMap();
};

DS.soundtrackYourBrand.prototype.addListeners = function () {
  this.elements.$close.on('click', function () {
    this.destroyActiveSoundtrack();
  }.bind(this));

  this.elements.$regionSelect.on('change', function () {
    this.filterRegions();
  }.bind(this));
};

DS.soundtrackYourBrand.prototype.initMap = function () {
  if (!this.elements.map || this.map) return;

  this.map = new window.google.maps.Map(this.elements.map, {
    center: {lat: 25, lng: 13},
    zoom: 3,
    styles: [{featureType:"all",elementType:"all",stylers:[{visibility:"off"}]},{featureType:"all",elementType:"geometry.fill",stylers:[{visibility:"off"},{color:"#760000"}]},{featureType:"administrative.country",elementType:"all",stylers:[{visibility:"off"}]},{featureType:"landscape",elementType:"all",stylers:[{visibility:"off"}]},{featureType:"landscape",elementType:"geometry.fill",stylers:[{color:"#cacaca"},{visibility:"on"}]},{featureType:"landscape.natural.landcover",elementType:"all",stylers:[{color:"#830000"},{visibility:"off"}]},{featureType:"landscape.natural.terrain",elementType:"all",stylers:[{color:"#000000"},{visibility:"off"}]},{featureType:"water",elementType:"all",stylers:[{visibility:"on"},{lightness:-100},{color:"#ffffff"}]}],
    scrollwheel: false,
    zoomControl: true,
    zoomControlOptions: {
      position: window.google.maps.ControlPosition.LEFT_TOP
    },
    mapTypeControl: false,
    fullscreenControl: false,
    streetViewControl: false,
    scaleControl: false
  });

  var activeIconWidth = 32;
  var activeIconHeight = 32;
  var inactiveIconWidth = 16;
  var inactiveIconHeight = 16;
  this.activeIcon = {
    url: this.componentData.activeMarker,
    size: new window.google.maps.Size(activeIconWidth, activeIconHeight),
    origin: new window.google.maps.Point(0, 0),
    anchor: new window.google.maps.Point((activeIconWidth / 2), (activeIconHeight / 2))
  };
  this.inactiveIcon = {
    url: this.componentData.defaultMarker,
    size: new window.google.maps.Size(inactiveIconWidth, inactiveIconHeight),
    origin: new window.google.maps.Point(0, 0),
    anchor: new window.google.maps.Point((inactiveIconWidth / 2), (inactiveIconHeight / 2))
  };

  for (var i = 0; i < this.properties.length; i++) {
    var property =  this.properties[i];
    if (!property.latitude || !property.longitude) continue;
    var marker = new window.google.maps.Marker({
      icon: this.inactiveIcon,
      map: this.map,
      position: {lat: parseFloat(property.latitude), lng: parseFloat(property.longitude)},
      propertyId: property.id,
      propertyName: property.name,
      setActiveProperty: this.setActiveProperty.bind(this),
    });
    window.google.maps.event.addListener(marker, 'click', function (event) {
      this.setActiveProperty(this.propertyId);
    });
    this.map.addListener('dragstart', function (event) {
      this.destroyActiveSoundtrack();
    }.bind(this));
    this.markers.push(marker);
  }

  if (!window.app.isMobileWindowSize()) this.playRandomProperty();
};

DS.soundtrackYourBrand.prototype.playRandomProperty = function (attemptNumber) {
  attemptNumber = attemptNumber || 0;
  if (attemptNumber === 3) return;
  var randomMarker = this.markers[Math.floor(Math.random() * this.markers.length)];
  this.getNowPlaying(randomMarker.propertyId, function (response) {
    try {
      var data = JSON.parse(response).data;
      if (!data || !data.nowPlaying || !data.nowPlaying.track || !data.nowPlaying.track.name) {
        this.playRandomProperty(attemptNumber + 1);
      } else {
        var newZoom = Math.max(this.map.getZoom(), 4);
        this.map.setZoom(newZoom);
        this.map.panTo(randomMarker.getPosition());
        this.displaySoundtrack(data, randomMarker, true);
      }
    } catch (error) {}
  }.bind(this));
};

DS.soundtrackYourBrand.prototype.setActiveProperty = function (propertyId) {
  for (var j = 0; j < this.markers.length; j++) {
    this.markers[j].setIcon(this.inactiveIcon);
  }
  var foundMarker = this.markers.filter(function (marker) {
    return marker.propertyId === propertyId;
  })[0];
  foundMarker.setIcon(this.activeIcon);
  var newZoom = Math.max(this.map.getZoom(), 4);
  this.map.setZoom(newZoom);
  this.map.panTo(foundMarker.getPosition());
  this.destroyActiveSoundtrack();
  this.displayLoader();
  this.getNowPlaying(propertyId, function (response) {
    try {
      var data = JSON.parse(response).data;
      this.displaySoundtrack(data, foundMarker);
    } catch (error) {
      console.log(error);
      this.displayError();
    }
  }.bind(this));
};

DS.soundtrackYourBrand.prototype.displaySoundtrack = function (data, marker, passive) {
  passive = passive || false;
  if (!data) {
    this.displayError();
    return;
  }
  var template = _.template(
    '<div class="inner">' +
    '  <div class="soundtrack-your-brand__active-soundtrack__album">' +
    '    <% if (track) { %>' +
    '      <% if (track.previewUrl) { %><button class="soundtrack-your-brand__active-soundtrack__play-button" aria-label="Play track"><% } %>' +
    '      <img src="<%- track.album.image.url %>" alt="<%- track.album.name %>" />' +
    '      <% if (track.previewUrl) { %></button><% } %>' +
    '    <% } %>' +
    '  </div>' +
    '  <% if (track && track.previewUrl) { %><div class="soundtrack-your-brand__active-soundtrack__audio-progress" aria-hidden="true"><span></span>/<span></span></div><% } %>' +
    '  <div class="soundtrack-your-brand__active-soundtrack__property_name"><%- propertyName %></div>' +
    '  <% if (track) { %>' +
    '    <div class="soundtrack-your-brand__active-soundtrack__now_playing">Now Playing</div>' +
    '    <div class="soundtrack-your-brand__active-soundtrack__track-name"><%- track.name %></div>' +
    '    <div class="soundtrack-your-brand__active-soundtrack__artist-name"><%- track.artists[0].name %></div>' +
    '    <% if (track.previewUrl) { %><audio src="<%- track.previewUrl %>"></audio><% } %>' +
    '  <% } else { %>' +
    '    <div class="soundtrack-your-brand__active-soundtrack__artist-name">There is nothing playing here currently.</div>' +
    '  <% } %>' +
    '</div>'
  );
  var templateData = {
    propertyName: marker.propertyName,
    track: data.nowPlaying && data.nowPlaying.track && data.nowPlaying.track.album.image.url ? data.nowPlaying.track : null
  };
  var html = template(templateData);
  this.elements.$element.addClass('active-soundtrack');
  if (templateData.track) {
    var activeProperty = _.find(this.properties, {'id': marker.propertyId});
    activeProperty.updateSoundtrack(data);
    this.elements.$properties.filter('[data-id="' + marker.propertyId + '"]').addClass('soundtrack-your-brand__property--active');
  }
  if (passive) {
    this.elements.$activeSoundtrack.html(html).show();
  } else {
    this.elements.$activeSoundtrack.html(html).show().attr('aria-live', 'polite');
  }

  var $audio = this.elements.$activeSoundtrack.find('audio');
  if ($audio.length) {
    var audio = $audio[0];
    var $audioCurrent = this.elements.$activeSoundtrack.find('.soundtrack-your-brand__active-soundtrack__audio-progress span:nth-child(1)');
    var $audioTotal = this.elements.$activeSoundtrack.find('.soundtrack-your-brand__active-soundtrack__audio-progress span:nth-child(2)');
    this.elements.$activeSoundtrackPlayButton = this.elements.$activeSoundtrack.find('.soundtrack-your-brand__active-soundtrack__play-button');

    if (!passive) {
      this.elements.$activeSoundtrackPlayButton.focus();
    }
    audio.addEventListener('loadedmetadata', function () {
      $audioCurrent.text(calculateCurrentValue(audio.currentTime));
      $audioTotal.text(calculateCurrentValue(audio.duration));
    });
    audio.addEventListener('timeupdate', function () {
      $audioCurrent.text(calculateCurrentValue(audio.currentTime));
    }, true);
    this.elements.$activeSoundtrackPlayButton.on('click.activeSoundtrackPlayButton', function () {
      this.elements.$activeSoundtrackPlayButton.toggleClass('playing');
      if (audio.paused === false) {
        audio.pause();
        this.elements.$activeSoundtrackPlayButton.attr('aria-label', 'Play track');
      } else {
        audio.play();
        this.elements.$activeSoundtrackPlayButton.attr('aria-label', 'Pause track');
      }
    }.bind(this));
  }
};

DS.soundtrackYourBrand.prototype.displayError = function () {
  this.elements.$activeSoundtrack.attr('aria-live', 'polite');
  this.elements.$element.addClass('active-soundtrack');
  var html =
    '<div class="inner">' +
    '  <img src="/wp-content/themes/w/assets/img/soundtrack-your-brand/error.svg" alt="error" class="soundtrack-your-brand__active-soundtrack__error-icon">' +
    '  <div class="soundtrack-your-brand__active-soundtrack__error">Sorry, please try again later.</div>' +
    '</div>';
  this.elements.$activeSoundtrack.html(html).show();
};

DS.soundtrackYourBrand.prototype.displayLoader = function () {
  this.elements.$activeSoundtrack.attr('aria-live', 'polite');
  this.elements.$element.addClass('active-soundtrack');
  var html =
    '<div class="inner">' +
    '  <img src="/wp-content/themes/w/assets/img/soundtrack-your-brand/loading.svg" alt="loading" class="soundtrack-your-brand__active-soundtrack__loading-icon">' +
    '  <div class="soundtrack-your-brand__active-soundtrack__loading">Loading</div>' +
    '</div>';
  this.elements.$activeSoundtrack.html(html).show();
};

DS.soundtrackYourBrand.prototype.destroyActiveSoundtrack = function (data) {
  this.elements.$element.removeClass('active-soundtrack');
  this.elements.$properties.removeClass('soundtrack-your-brand__property--active');
  this.elements.$activeSoundtrack.hide().html('').removeAttr('aria-live');
  if (this.elements.$activeSoundtrackPlayButton) {
    this.elements.$activeSoundtrackPlayButton.off('click.activeSoundtrackPlayButton').blur();
    this.elements.$activeSoundtrackPlayButton = null;
  }
};

DS.soundtrackYourBrand.prototype.getNowPlaying = function (propertyId, callback) {
  $.post(
    window.ajax_object.ajax_url,
    {
      'action': 'get_now_playing',
      'property_id': propertyId
    },
    callback
  );
};

DS.soundtrackYourBrand.prototype.filterRegions = function () {
  var regionId = this.elements.$regionSelect.val();
  if (regionId) {
    this.elements.$regions.hide();
    this.elements.$regions.filter('[data-id="' + regionId + '"]').show();
  } else {
    this.elements.$regions.show();
  }
  this.elements.$regionList.scrollTop(0);
};

$(function () {
  var $components = $('.soundtrack-your-brand-component');
  if (!$components.length) return;
  $components.each(function (i, el) {
    new DS.soundtrackYourBrand(el);
  });
});
