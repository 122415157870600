var DS = window.DS || {};

DS.soundtrackYourBrandProperty= function (el, setActiveProperty) {
  this.elements = {};
  this.elements.$element = $(el);
  this.elements.$button = this.elements.$element.find('button');
  this.id = this.elements.$element.data('id');
  this.latitude = this.elements.$element.data('latitude');
  this.longitude = this.elements.$element.data('longitude');
  this.name = this.elements.$element.data('name');
  this.setActiveProperty = setActiveProperty;

  this.addListeners();
};

DS.soundtrackYourBrandProperty.prototype.addListeners = function () {
  this.elements.$button.on('click', function () {
    this.setActiveProperty(this.id);
  }.bind(this));
};

DS.soundtrackYourBrandProperty.prototype.updateSoundtrack = function (data) {
  var template = _.template(
    '<div>' +
    '  <div class="soundtrack-your-brand__soundtrack__album">' +
    '    <% if (track) { %>' +
    '      <img src="<%- track.album.image.url %>" alt="<%- track.album.name %>" />' +
    '    <% } %>' +
    '  </div>' +
    '  <div>' +
    '    <div class="soundtrack-your-brand__property-name"><%- propertyName %></div>' +
    '    <% if (track) { %>' +
    '      <div class="soundtrack-your-brand__soundtrack__track-name"><%- track.name %></div>' +
    '      <div class="soundtrack-your-brand__soundtrack__artist-name"><%- track.artists[0].name %></div>' +
    '    <% } %>' +
    '  </div>' +
    '</div>'
  );
  var templateData = {
    propertyName: this.name,
    track: data.nowPlaying.track.album.image.url ? data.nowPlaying.track : null
  };
  var html = template(templateData);
  this.elements.$button.html(html);
};
