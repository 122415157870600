var DS = window.DS || {};
DS.filterableList = function() {
  var els = {};

  var listen = function() {
    els.topLevelList.on('change',onTopLevelListChange);
    els.sublistSelects.on('change',onSublistChange);
    els.button.on('click',onButtonClick);
  };

  var onTopLevelListChange = function() {
    var topLevelValue = els.topLevelList.val();
    var valueClass = '.sublist-index-' + topLevelValue;
    els.sublists.not(valueClass).removeClass('current');
    els.sublists.filter(valueClass).addClass('current');
    setTimeout(function() {
      buttonDisableCheck();
    });
  };

  var onSublistChange = function() {
    buttonDisableCheck();
  };

  var buttonDisableCheck = function() {
    var currSublistValue = els.sublists.filter('.current').find('select').val();
    els.button.toggleClass('disabled',currSublistValue === '0');
  };

  var onButtonClick = function() {
    var currSublistValue = els.sublists.filter('.current').find('select').val();
    if(currSublistValue !== '0') {
      window.open(currSublistValue);
    }
  };

  return {
    init: function(el) {
      var $el = $(el);
      els = {
        component: $el,
        topLevelList : $el.find('.top-level-list'),
        sublists : $el.find('.sublists .sublist'),
        sublistSelects: $el.find('.sublists .sublist select'),
        button: $el.find('.button button')
      };
      listen();
    }
  };
};

$(function() {
  $('.filterable-list-component').each(function(i,el) {
    var filterableList = new DS.filterableList();
    filterableList.init(el);
  });
});
