var DS = window.DS || {};
DS.social = function() {
    var els = {};
    var vars = {};

    var facebookShare = function () {
      els.component.find('.fb-share').click(function(e) {
        e.preventDefault();
        var url = encodeURIComponent($(this).attr('href'));
        window.open('https://www.facebook.com/sharer.php?u=' + url, 'fbShareWindow', 'height=450, width=550, top=' + ($(window).height() / 2 - 275) + ', left=' + ($(window).width() / 2 - 225) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
      });
    };

    var twitterShare = function () {
      els.component.find('.twitter-share').click(function(e){
        e.preventDefault();
        var loc = encodeURIComponent($(this).attr('href'));
        var title  = encodeURIComponent($(this).attr('title'));
        //We trigger a new window with the Twitter dialog, in the middle of the page
        window.open('http://twitter.com/share?url=' + loc + '&text=' + title + '&', 'twitterwindow', 'height=450, width=550, top='+($(window).height()/2 - 225) +', left='+$(window).width()/2 +', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
      });
    };

    var listen = function () {
      facebookShare();
      twitterShare();

      if (window.innerWidth > 768) {
        writeLayout();
      }

      function writeLayout() {
        vars.scrollTop = $(window).scrollTop();
        vars.bodyHeight = $('body').height();
        var heroImageHeight = els.heroImage.innerHeight();
        var lastComponentTop = els.lastComponent.offset().top + 193;
        if (vars.scrollTop >= heroImageHeight) {
          els.component.addClass('pegged');
          if (vars.scrollTop + vars.componentHeight >= lastComponentTop) {
            els.component.addClass('bottom');
            els.component.css('top', lastComponentTop - vars.componentHeight);
          } else {
            els.component.removeClass('bottom');
            els.component.attr('style','');
          }
        } else if (vars.scrollTop < heroImageHeight) {
          els.component.removeClass('bottom pegged');
        }
        window.requestAnimationFrame(writeLayout);
      };
    };

    return {
        init: function(el) {
            var $el = $(el);
            els = {
                component: $el,
                heroImage: $('.hero-component .image'),
                lastComponent: $('.site-content > .related-categories-component')
            };
            vars = {
              scrollTop: $(window).scrollTop(),
              windowHeight: $(window).height(),
              bodyHeight: $('body').height(),
              componentHeight: els.component.innerHeight()
            };
            listen();
        }
    };
};

$(function() {
    $('.social-sharing-component').each(function(i,el) {
        var social = new DS.social();
        social.init(el);
    });
});
