var DS = window.DS || {};
DS.modalInstagram = function() {
  var els = {};
  var vars = {};

  var listen = function() {
    els.instagramLink.on({
      click: function(e){
        e.preventDefault();
        if (!$(this).hasClass('disabled')) {
          openModal($(this).data('instagramId'));
        };
      }
    });

    els.closeModal.on({
      click: function(e){
        closeModal();
      }
    });

    els.body.on('click', '.arrow.prev', function(){
      var prevID = getPrevID();
      if (prevID) {
        openModal(prevID);
      }
    });

    els.body.on('click', '.arrow.next', function(){
      var nextID = getNextID();
      if (nextID) {
        openModal(nextID);
      }
    });

    els.body.on({
      keyup: function(e) {
        if (e.keyCode === 27) closeModal();   // esc
      }
    });
  };

  var getPrevID = function () {
    return $('a[data-instagram-id="'+vars.curID+'"]').closest('.instagram-grid-block').prev().find('> a').attr('data-instagram-id');
  };

  var getNextID = function () {
    return $('a[data-instagram-id="'+vars.curID+'"]').closest('.instagram-grid-block').next().find('> a').attr('data-instagram-id');
  };

  var openModal = function(instagramID) {
      vars.isOpen = true;
      vars.curID = instagramID;
      var instagramData = vars.instagramData[instagramID];
      var modalHtml = vars.modalHtml;
      modalHtml = modalHtml.replace('<img class="instagram-image" src="" alt="%text%">', '<img class="instagram-image" src="'+instagramData.images.standard_resolution.url+'" alt="'+instagramData.caption.text+'">');
      modalHtml = modalHtml.replace('<img class="instagram-logo" src="" alt="%instagram-user%">', '<img class="instagram-logo" src="'+instagramData.user.profile_picture+'" alt="'+instagramData.user.full_name+'">');
      modalHtml = modalHtml.replace(/%username%/g, instagramData.user.username);
      var created_time = Sugar.Date.medium(new Date(parseInt(instagramData.created_time*1000)));

      var daysSince = Sugar.Date.relative(new Date(parseInt(instagramData.created_time*1000)));
      modalHtml = modalHtml.replace('%created_time%', daysSince);
      modalHtml = modalHtml.replace('%text%', instagramData.caption.text);


      els.modalContent.empty().append(modalHtml);

      els.component.removeClass( 'close' );
      els.body.addClass( 'modal-open' );
      els.component.addClass( 'open' );

      // Set focus to close button for accessibility
      els.closeModal.focus();

      if (!getPrevID()) {
        els.component.find('.prev').addClass('disabled');
      } else {
        els.component.find('.prev').removeClass('disabled');
      }

      if (!getNextID()) {
        els.component.find('.next').addClass('disabled');
      } else {
        els.component.find('.next').removeClass('disabled');
      }
  };

  var closeModal = function() {
    if (vars.isOpen) {
      els.component.addClass( 'close' );
      els.body.removeClass( 'modal-open' );
      els.component.removeClass( 'open' );
    }
  };

  return {
    init: function(el) {
      var $el = $(el);

      els = {
        component: $el,
        instagramComponent: $( '.instagram-modal' ),
        modalContent: $el.find('.modal-content'),
        instagramLink: $( 'a[data-instagram-id]' ),
        body: $( 'body' ),
        closeModal: $el.find( '.close-modal' ),
        modal: $el.find( '.modal' )
      };

      vars = {
        instagramData: window.MARRIOTT_INSTAGRAM_DATA,
        lang: lang = $('html')[0].lang,
        isOpen: false,
        curID: null,
        modalHtml: els.modalContent.html()
      };

      Sugar.Date.setLocale(vars.lang);

      if ( !window.app.isMobile() ) listen();
    }
  };
};

$(function() {
    var $components = $( '.instagram-modal' );

    if ( $components.length > 0 ) {
        $components.each(function(i,el) {
            var modal = new DS.modalInstagram();
            modal.init(el);
        });
    }
});
