var DS = window.DS || {};
DS.scrollanimations = function() {
  var els = {};
  var vars = {};


  function componentAnimateOnScroll (component) {
    component.each(function(){
      var _this = $(this);

      var scene = new ScrollMagic.Scene({triggerElement: _this,  triggerHook: 0.84})
      //enable indicator JS in gulpfile and uncomment below to enable
      //.addIndicators()
      .addTo(vars.smController)
      .on('enter', function(e){
        _this.addClass('animate-in');
      });
    });
  };


  return {
    init: function(el) {
      els = {
        components: [
          $('.carousel-component').not('.mobile-only'),
          $('.strip-component'),
          $('.copy-row-component'),
          $('.video-component'),
          $('.grid-component'),
          $('.cards-component')
        ],

      };
      vars = {
        smController: new ScrollMagic.Controller()
      };

      for(var i = 0; i < els.components.length;i++) {
        componentAnimateOnScroll(els.components[i]);
      }

    }
  };
};

$(function() {

    var scrollanimations = new DS.scrollanimations();
    scrollanimations.init();

});
