var DS = window.DS || {};
DS.moveSiteBackground = function() {
	var els = {};
	var vars = {};

	var listen = function() {
    $(window).scroll(function() {
      window.requestAnimationFrame(writeLayout);
    });

    $(window).resize(writeLayout);

    function writeLayout() {
      updateConfigVars();
      moveSiteBackground();
    };
	};

  var moveSiteBackground = function(){
    $siteBackground = els.siteBackground;
    if($siteBackground.css('position') === 'fixed'){
      var footerHeight = vars.footerHeight;
      var bodyHeight = vars.bodyHeight;
      var scrollTop = vars.scrollTop;
      var siteBackgroundPos = 0;

      if(scrollTop + vars.windowHeight > bodyHeight - footerHeight) {
        var siteBackgroundPos = scrollTop + vars.windowHeight - (bodyHeight - footerHeight);
        siteBackgroundPos = siteBackgroundPos*-1;
      }

      if(siteBackgroundPos > 0){
        siteBackgroundPos = 0;
      } else if(siteBackgroundPos <= footerHeight*-1){
        siteBackgroundPos = footerHeight*-1;
      }

      $siteBackground.css('transform', 'translate3d( -50%, ' + siteBackgroundPos + 'px, 0)');
    }
  }

  var updateConfigVars = function(){
    vars.scrollTop = $(window).scrollTop();
    vars.windowHeight = $(window).height();
    vars.footerHeight = $('.footer-component .bottom').outerHeight() + $('.footer-component .footer-meta').outerHeight();
    vars.bodyHeight = Math.ceil($('.site').height()) + vars.footerHeight;
  };

	return {
		init: function(el) {

			var $el = $(el);

			els = {
        item: $el,
        siteBackground: $('.site-background')
			};

			vars = {
        defaultRate: 5,
        scrollTop: null,
        windowHeight: null,
        bodyHeight: null,
        footerHeight: null
      };

      setInterval(function(){
        updateConfigVars();
        moveSiteBackground();
      },10000);

			listen();
		}
	};
};

$(function() {
	var $items = $( '.move-site-background' );

	if ( $items.length > 0 ) {
			$items.each(function(i,el) {
        if (!window.app.isMobile()) {
					var moveSiteBackground = new DS.moveSiteBackground();
          moveSiteBackground.init(el);
        }
      });
	}
});
