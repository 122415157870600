var DS = window.DS || {};
DS.grid = function() {
  var els = {};
  var vars = {};

  var listen = function() {
    if(vars.hasLoadMore) {
      els.loadMore.on('click', function(){
        var hiddenBlocks = els.component.find('.grid-block-component.hide');
        var blocksToReveal = hiddenBlocks.slice(0,vars.loadInterval);
        blocksToReveal.removeClass('hide');
        if(hiddenBlocks.length <= blocksToReveal.length) {
          els.loadMore.hide();
        }
        if (vars.hasIsotope) {
          els.component.isotope();
        }
      });
    }
  };

  var initIsotope = function() {

    els.component.find('img').on('load', function(){
      els.component.isotope();
    });

    els.component.isotope({
      itemSelector: '.grid-block-component',
      percentPosition: true,
      masonry: {
        columnWidth: '.grid-sizer'
      },
      transitionDuration: 0
    });
  };

  return {
    init: function(el) {
      var $el = $(el);
      els = {
        component: $el,
        loadMore : $el.find('.grid-component__controls .action-show')
      };
      vars = {
        loadInterval: $el.attr('data-load-interval'),
        hasIsotope: !$el.hasClass('no-isotope'),
        hasLoadMore: $el.hasClass('load-more')
      };
      if (vars.hasIsotope) {
        initIsotope();
      }
      listen();
    }
  };
};

$(function() {
  $(".grid-component").each(function(i,el) {
    var grid = new DS.grid();
    grid.init(el);
  });
});
