var DS = window.DS || {};
DS.featuredEvents = function(){
  var els = {};
  var vars = {};

  var listen = function(){
    els.interestSelect.on('change', interestSelectChange);
    els.arrivalDateInput.on('change input', arrivalDateInputChange);
    els.departureDateInput.on('change input', departureDateInputChange);
    $(window).on('datepickerSelect', datepickerSelect);
  };

  var interestSelectChange = function () {
    vars.selectedInterest = $(this).val();
    filterEvents();
  };

  var arrivalDateInputChange = function () {
    vars.datepickerStartDate = new Date(els.arrivalDateInput.val());
  };

  var departureDateInputChange = function () {
    vars.datepickerEndDate = new Date(els.departureDateInput.val());
  };

  var datepickerSelect = function () {
    arrivalDateInputChange();
    departureDateInputChange();
    filterEvents();
  };

  var filterEvents = function () {
    var oddEvenCount = 0;
    els.cards.each(function () {
      $this = $(this);
      var match = false,
        eventStartDateString = $this.attr('data-start-date'),
        eventStartDateParts = eventStartDateString.split("/"),
        eventStartDateObject = new Date(eventStartDateParts[2], eventStartDateParts[1] - 1, eventStartDateParts[0]),
        eventEndDateString = $this.attr('data-end-date'),
        eventEndDateParts = eventEndDateString.split("/"),
        eventEndDateObject = new Date(eventEndDateParts[2], eventEndDateParts[1] - 1, eventEndDateParts[0]),
        interests = $(this).attr('data-interest').split(',');

      for(var i = 0; i < interests.length; i++){
        var interest = interests[i].trim().toLowerCase();
        if (interest === vars.selectedInterest || vars.selectedInterest === 'default') {
          if ((eventStartDateObject >= vars.datepickerStartDate && eventStartDateObject <= vars.datepickerEndDate) || (eventEndDateObject >= vars.datepickerStartDate && eventEndDateObject <= vars.datepickerEndDate) || (eventStartDateObject <= vars.datepickerStartDate && eventEndDateObject >= vars.datepickerEndDate)) {
            match = true;
            oddEvenCount++;
          }
        }
      }

      if (match) {
        $this.addClass('match').removeClass('odd even');
        if (oddEvenCount % 2 == 0) {
          $this.addClass('even');
        } else {
          $this.addClass('odd');
        }
      } else{
        $this.removeClass('match');
      }
    });
  };

  var buildInterests = function () {
    var selectHtml = '<option value="default">'+vars.componentData.langAllInterests+'</option>';
    els.cards.each(function () {
      var interests = $(this).attr('data-interest');
      interests = interests.split(',');
      for (var i = 0; i < interests.length; i++) {
        var interest = interests[i].trim();
        if (vars.interests.indexOf(interest.toLowerCase()) == -1) {
          vars.interests.push(interest.toLowerCase());
          selectHtml += '<option value="'+interest.toLowerCase()+'">'+interest+'</option>';
        }
      };
    });
    els.interestSelect.empty().append(selectHtml);
  };

  return {
    init: function(el){
      var $el = $(el);
      els = {
        component: $el,
        cards: $el.find('.card'),
        arrivalDateInput: $el.find('.arrival-date'),
        departureDateInput: $el.find('.departure-date'),
        interestSelect: $el.find('.interest-select')
      };

      vars = {
        componentData: els.component.data(),
        interests: [],
        selectedInterest: 'default',
        datepickerStartDate: new Date('1970/01/01'),
        datepickerEndDate: new Date('3000/01/01'),
      },
      buildInterests();
      listen();
    }
  };
};

$(function(){
  if ( $('.featured-events-component').length > 0 ){
    $('.featured-events-component').each(function(i,el){
      var featuredEvents = new DS.featuredEvents();
      featuredEvents.init(el);
    });
  }
});
