var DS = window.DS || {};
DS.cards = function() {
  var els = {};
  var vars = {};

  var listen = function() {
    els.loadMore.on('click', function(){
      var showCount = parseInt(vars.loadInterval) + parseInt(vars.loadCount),
          _this = $(this);
      els.cards.slice(0, showCount).removeClass('hide');
      vars.loadCount = showCount;
      checkIfAllHidden(els.cards, _this);
    })
  };

  var checkIfAllHidden = function(cards, _this) {
    var hideCount = 0;
    for(var i=0;i<cards.length;i++) {
      if(cards[i].classList.contains('hide')) {
        hideCount++;
      }
    }
    if(hideCount===0) {
      $(_this).parent('.cards-component__load-more-container').remove();
    }
  };

  return {
    init: function(el) {
      var $el = $(el);
      els = {
        component: $el,
        cards: $el.find('.card'),
        loadMore : $el.find('.cards-component__load-more')
      };

      vars = {
        loadInterval: $el.attr('data-load-interval'),
        loadCount: $el.attr('data-load-interval')
      }
      listen();
    }
  };
};

$(function() {
  if ( $('.cards-component').length > 0 ) {
    $('.cards-component').each(function(i,el) {
      var cards = new DS.cards();
      cards.init(el);
    });
  }
});
