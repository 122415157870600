var DS = window.DS || {};
DS.footer = function() {
  var els = {};

  var listen = function() {
    els.bottomLinks.on('focus',scrollToBottom);
  };

  var scrollToBottom = function() {
    if(!window.app.isMobile()) {
      window.scrollTo(0,document.body.scrollHeight);
    }
  }

  var addBottomMargin = function() {
    var totalHeight = els.footerBottom.innerHeight() + els.footerCopyright.innerHeight();
        els.site.css('margin-bottom', totalHeight + 'px');
  };

  var adjustFixedBottomElement = function() {
    els.footerBottom.css('bottom', els.footerCopyright.innerHeight());
  };

  var adjustMarginOnResize = function() {
    var adjustFooter;
    window.addEventListener('resize', function () {
      if (!window.app.isMobile()) {
        clearTimeout(adjustFooter);
        adjustFooter = setTimeout(function() {
          addBottomMargin();
          adjustFixedBottomElement();
        },100);
      } else {
        els.footerBottom.css('bottom', '');
        els.site.css('margin-bottom', '');
      }
    });
  };

  var adjustMarginOnbrandRibbonHeight = function() {
    var brandRibbon = document.querySelector('pacsys-brand-ribbon');

    brandRibbon.addEventListener('brandRibbonHeight', function () {
      addBottomMargin();
    });

    document.addEventListener('brandRibbonHeight', function () {
      addBottomMargin();
    });
  }

  return {
    init: function() {
      els = {
        site : $('.site'),
        footerBottom : $('.footer-component').find('.bottom'),
        footerCopyright : $('.footer-component').find('.footer-meta'),
        bottomLinks : $('.footer-component .bottom a, .footer-component .footer-meta a')
      };
      adjustMarginOnResize();
      if (!window.app.isMobile()) {
        addBottomMargin();
        adjustFixedBottomElement();
        adjustMarginOnbrandRibbonHeight();
      }
      listen();
    }
  };
};

$(function() {
  var footer = new DS.footer();
  footer.init();
});
