var DS = window.DS || {};
var openTabClass = 'first-tab-mobile';
DS.tabs = function() {
  var els = {};
  var vars = {};
  var isRTL = document.dir == 'rtl';

  var buildTabContent = function() {
    if ( isRTL ) { els.slides.attr('dir','rtl') };

    els.slides.find('img').on('load', function(){
      els.slides.slick('resize');
    });

    if ( isRTL ) { els.slides.attr('dir','rtl') };
    els.slides.not('.slick-initialized').slick({
      arrows: false,
      infinite: false,
      fade: true,
      dots: false,
      slidesToShow: 1,
      waitForAnimate: false,
      touchMove: false,
      swipe: false,
      accessibility: false,
      rtl: isRTL
    });
  };

  var buildTabNavigationControls = function () {
    /**
    * Keycodes
    * 9 : Tab
    * 13: enter
    * 16: Shift
    * 37: left arrow
    * 38: up arrow
    * 39: right arrow
    * 40: down arrow
    */
    els.labels.on({
      click: function() {
        setActiveTab($(this));
      },
      keydown: function(e) {
        //enter key
        if(e.keyCode === 13) {
          setActiveTab($(this));
          e.preventDefault();
        }

        //left arrow or up arrow
        if(e.keyCode === 37 || e.keyCode === 38) {
          var prevItem = $(e.currentTarget).prev('.tab-label');

          if (prevItem.length) {
            setActiveTab(prevItem);
          } else {
            var lastItem = $(e.currentTarget).siblings().last();
            setActiveTab(lastItem);
          }

          e.preventDefault();
        }
        // Right arrow or down arrow
        else if(e.keyCode === 39 || e.keyCode === 40) {
          var nextItem = $(e.currentTarget).next('.tab-label');

          setNextItem(e.currentTarget, nextItem, true);
          e.preventDefault();
        }

        // Shift and tab
        else if(event.shiftKey && e.keyCode === 9) {
          var prevItem = $(e.currentTarget).prev('.tab-label');

          if (prevItem.length) {
            setNextItem(e.currentTarget, prevItem, true);
            e.preventDefault();
          }
        }
      }
    });
  };

  /**
  * Find the current search button in tab on st regis and focuses the next tab
  */
  var searchButtonTabbing = function() {
    if (els.searchButtons.length) {
      els.searchButtons.on({
        keydown: function(e) {
          //tab key
          if (e.keyCode === 9) {
            setNextItem(getCurrentTab(), getCurrentTab().next(), false);
          }
        }
      });
    }
  }

  /**
  * Set next tabbed item
  */
  var setNextItem = function(currentTab, nextItem, allowNextItem) {
    if (nextItem.length) {
      setActiveTab(nextItem);
    } else if (allowNextItem) {
      firstItem = $(currentTab).siblings().first();
      setActiveTab(firstItem);
    }
  }

  /**
  * Gets current active tab
  *
  * @return $el
  */
  var getCurrentTab = function() {
    //finding currentTab on the page
    return els.stacked.find('.tab-label.active[aria-selected="true"]');
  }

  var setActiveTab = function($tab) {
    var isStackedComponent = $tab.parents('.stacked');
    var activeSlide = els.slides.find('.slick-active');

    if(!$tab.hasClass('active')) {
      var index = $tab.attr('data-tab-index');
      els.labels.removeClass('active');
      els.slides.slick('slickGoTo',index);

      // Remove tabindex from all play buttons & links (ADA)
      els.slides.find('.link').attr('tabindex', -1);
      els.playButton.attr('tabindex', -1);

      activeSlide = els.slides.find('.slick-active');
      els.activeSlide = activeSlide;

      //There are different variations of tab components so lets only do things if this is a stacked tab component
      if (isStackedComponent.length) {
        //Don't allow old tabs to be focusable
        els.stacked.find('.tab-labels').attr('aria-selected', 'false').attr("tabindex", "-1");
        activeSlide.siblings('.tab-content-panels-container-mobile').find('.tab-labels > .tab-label').attr('aria-selected', 'false').attr('tabindex', -1);

        // Remove all tabindex (ADA)
        // els.slides.find('.tab-content-panel *').attr('tabindex', -1);
        els.slides.find('.tab-content-panel select, .tab-content-panel button').attr('tabindex', '-1');
        els.slides.find('.tab-labels *').attr('tabindex', -1);
        els.slides.find('.tab-labels *').attr('aria-hidden', 'true');

        // Set tabindex for active slide (ADA)
        activeSlide.find('.tab-content-panel select, .tab-content-panel button').attr('tabindex', 0);

        /**
        * There is bad html code that has multiple tabs so we have to find things by tabindex attribute and by current active slide
        */
        var newTab = activeSlide.find('.tab-label[data-tab-index="' + index + '"]');
        newTab.addClass('active').attr('tabindex', 0).attr('aria-selected', 'true').attr('aria-hidden', 'false').focus();
        newTab.siblings().attr('tabindex', -1);

        setTimeout(function() {
          newTab.focus();
        }, 0);
      }
      else {
        $tab.addClass('active');
        $tab.focus();
      }
    }
    else {
      setFocus(activeSlide);
    }
  };

  // Set focus to play-button/link in tab (ADA)
  var setFocus = function(activeSlide) {
    activeSlide.find('.vjs-big-play-button').attr('tabindex', 0);
    activeSlide.find('.link').attr('tabindex', 0);

    if ( activeSlide.find('.vjs-big-play-button').length !== 0 ) {
      vars.activeTabLink = setTimeout(function() {
        activeSlide.find('.vjs-big-play-button').focus();
      }, 100);
    }
    else {
      vars.activeTabLink = setTimeout(function() {
        activeSlide.find('.tab-content-panel').children(".link").focus();
      }, 100);
    }
  };

  var destroyTabCarousel = function() {
    els.slides.slick('unslick');
  };

  var openPanels = function() {
    els.labelMobile.off().on('click', function() {
      var speed = 500;
      var $mobilePanelContainer = $(this).parents('.tab-content-panels-container-mobile');
      if($mobilePanelContainer.hasClass('open')) {
        $mobilePanelContainer.removeClass('open').find('.wrapper').slideUp(speed);
      } else {
        els.mobilePanelContainers.removeClass('open').find('.wrapper').slideUp(speed);
        $mobilePanelContainer.addClass('open').find('.wrapper').slideDown(speed, function () {
          $('html, body').animate({
            scrollTop: $(this).parents('.tab-content-panels-container-mobile').offset().top
          }, speed);
        });
      }
    });
  };

  var resizeTabs = function() {
    window.addEventListener('resize', function () {
      if(window.innerWidth < 769 && !els.component.hasClass('no-panels-mobile')) {
        if(els.slides.hasClass('slick-initialized')) {
          clearTimeout(vars.adjustTabsComponentMobile);
          vars.adjustTabsComponentMobile = setTimeout(function() {
            destroyTabCarousel();
            openPanels();
          },100);
        }
      } else {
        if(!els.slides.hasClass('slick-initialized')) {
          clearTimeout(vars.adjustTabsComponentDesktop);
          vars.adjustTabsComponentDesktop = setTimeout(function() {
            buildTabContent();
            buildTabNavigationControls();
            els.mobilePanelContainers.removeClass('open');
          },100);
        }
      }
    });
  };

  return {
    init: function(el) {
      var $el = $(el);
      els = {
        component: $el,
        stacked: $('.stacked.tabs-component'),
        slides : $el.find('.tab-slide-wrapper'),
        slide : $el.find('.tab-slide'),
        labels : $el.find('.tab-label'),
        labelMobile : $el.find('.tab-label-mobile'),
        mobilePanelContainers: $el.find('.tab-content-panels-container-mobile'),
        playButton: $el.find('.vjs-big-play-button')
      };
      vars = {
        adjustTabsComponentMobile: null,
        adjustTabsComponentDesktop: null
      };

      if (window.innerWidth > 768 || els.component.hasClass('no-panels-mobile')) {
        buildTabContent();
        buildTabNavigationControls();
      } else {
        openPanels();
      }

      resizeTabs();


      /**
      * have to set these els below after slick initialized in buildTabContent function
      **/
      //get all the stacked component search buttons
      els.searchButtons = els.stacked.find('.slick-slide .button button');

      //calling this after everything has initialized
      if (els.searchButtons.length) {
        searchButtonTabbing();
      }
    }
  };
};

$(function() {
  $('.tabs-component').each(function(i,el) {
    var tabs = new DS.tabs();
    tabs.init(el);
		if ($(this).hasClass(openTabClass) ) {
			var $firstPanel = $(this).find('.tab-content-panels-container-mobile').first();
			$firstPanel.addClass('open').find('.wrapper').show();
		}
  });
});
