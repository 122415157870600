(function(window, factory) {
  // universal module definition
  /* jshint strict: false */ /*globals define, module, require */
  if (typeof define === 'function' && define.amd) {
    // AMD
    define(['isotope/js/layout-mode'], factory);
  } else if ( typeof exports === 'object' ) {
    // CommonJS
    module.exports = factory(
      require('isotope-layout/js/layout-mode')
    );
  } else {
    // browser global
    factory(window.Isotope.LayoutMode);
  }
}(window, function factory(LayoutMode) {
  'use strict';

  var SpineAlignOffset = LayoutMode.create('spineAlignOffset');
  var proto = SpineAlignOffset.prototype;

  proto._resetLayout = function () {
    this.itemIndex = 0;
    this.cols = 2;
    this.col1Height = this.options.col1Height || 0;
    this.col2Height = this.options.col2Height || 0;
    this.col1WidthPercent = this.options.col1WidthPercent || 0.645;
    this.col2WidthPercent = this.options.col2WidthPercent || 0.315;
    this.spineWidthPercent = 0.04;
  };

  proto._getItemLayoutPosition = function (item) {
    var col;
    var x;
    var y;

    item.getSize();
    // if the block doesn't fit in the right column, it belongs in the left column
    if(item.size.outerWidth/this.isotope.size.outerWidth > this.col2WidthPercent) {
      col = 0;
    } else {
      // if the block fits in both columns, it goes in the shortest column
      if(this.col1Height < this.col2Height) {
        col = 0;
      } else if(this.col1Height > this.col2Height) {
        col = 1;
      } else {
        // if the block fits in both columns and both columns are equally tall,
        // it goes in the left column if the index is even and the right if it's odd
        col = this.itemIndex % this.cols;
      }
    }
    if(col === 0) {
      x = (this.col1WidthPercent*this.isotope.size.outerWidth) - item.size.outerWidth;
      y = this.col1Height;
      this.col1Height += item.size.outerHeight;
    } else {
      x = (this.col1WidthPercent + this.spineWidthPercent)*this.isotope.size.outerWidth;
      y = this.col2Height;
      this.col2Height += item.size.outerHeight;
    }
    this.itemIndex++;
    return {
      x: x,
      y: y
    };
  };

  proto._getContainerSize = function (data) {
    return {
      height: Math.max(this.col1Height, this.col2Height)
    };
  };

  return SpineAlignOffset;
}));
