var DS = window.DS || {};
DS.specialRatesSelect = function () {
  var els = {};
  var vars = {};

  var listen = function () {
    els.component.siblings().find('input, button').on({
      focus: function () {
        if (!els.specialRatesPanel.hasClass('is-hidden')) {
          onSpecialRatesClicked();
        }
      }
    });

    els.component.parents('.booking-component').on({
      click: function (evt) {
        evt.stopPropagation();
        var isSpecialRatesTrigger = $(evt.target).is(els.specialRatesTrigger);
        var clickedOutsideOfSpecialRates = !vars.isMobile && !isSpecialRatesTrigger && els.specialRatesTrigger.has(evt.target).length === 0 && els.specialRatesPanel.has(evt.target).length === 0 && els.specialRatesPanel.is(":visible");

        // check if the clicked area is special rates dropdown or not
        // close special rates dropdown when clicked outside
        if (clickedOutsideOfSpecialRates) {
          onSpecialRatesClicked();
        }
      }
    });

    els.specialRatesTrigger.on({
      focus: function (evt) {
        evt.preventDefault();
        if (!vars.isMobile) {
          $(this).trigger('mousedown');
        }
      },
      mousedown: function (evt) {
        evt.preventDefault();
        if (!vars.isMobile) {
          onSpecialRatesClicked();
        }
      }
    });

    els.rateOptions.on({
      change: function (evt) {
        onRateSelection(evt);
      }
    });

    els.useRewardsPoints.on({
      change: function (evt) {
        onUsePointsChange(evt);
      }
    });

    els.specialRatesCode.on({
      focusout: function (evt) {
        onRatesCodeChange(evt);
      }
    })
  };

  var onRatesCodeChange = function(evt) {
    var target = $(evt.target);
    var code = target.val();
    if (code.length) {
      updateSpecialRatesFieldString(code);
    } else {
      // switch back to None
      var defaultRate = els.rateOptions.eq(0);
      defaultRate.prop("checked", true);
      updateSpecialRatesFieldString(defaultRate.parents('.special-rates-toggle').find('label').text());
      // hide
      els.codeContainer.hide();
      els.corpCode.hide();
    }
  }

  var clearUsePoints = function() {
    els.useRewardsPoints.prop('checked', false);
  }

  var onUsePointsChange = function(evt) {
    // set special rates to none
    if ($(evt.target).prop('checked')) {
      clearRateSelection();
    }
  }

  var clearRateSelection = function() {
    var defaultRate = els.rateOptions.filter('input[value="none"]');
    defaultRate.prop("checked", true);

    if (vars.isMobile) {
      els.component.parents('.booking-component')
        .siblings('.booking-component')
        .find('input[name="clusterCode"]')
        .filter('input[value="none"]')
        .prop("checked", true);
    }

    updateSpecialRatesFieldString(defaultRate.parents('.special-rates-toggle').find('label').text());
    els.codeContainer.hide();
    els.corpCode.hide();
  }

  var onRateSelection = function(evt) {
    var target = $(evt.target);
    var rateTitle = target.parents('.special-rates-toggle').find('label').text();
    var rateValue = target.val();
    clearUsePoints();

    if (rateValue === 'corp') {
      els.codeContainer.show();
      els.corpCode.show();
      els.corpCode.find('input').focus();
    } else {
      updateSpecialRatesFieldString(rateTitle);
      els.codeContainer.hide();
      els.corpCode.hide();
      if (!vars.isMobile) {
        onSpecialRatesClicked();
      }
    }
  }

  var onSpecialRatesClicked = function () {
    els.specialRatesPanel.slideToggle("fast")
      .attr('aria-expanded', els.specialRatesPanel.attr('aria-expanded') === 'false' ? 'true' : 'false')
      .toggleClass('is-hidden')
      .parents(".form-group")
      .find('.dropdown-trigger')
      .toggleClass('dropdown-is-active');
  };

  var updateSpecialRatesFieldString = function (title) {
    els.displayText.text(title);
    els.screenReaderEl.text(title);

    if (vars.isMobile) {
      els.component.parents('.booking-component')
        .siblings('.booking-component')
        .find('.special-rates-select')
        .find('.display-text')
        .text(title);
    }
  };

  var getUseRewardsErrorMessage = function() {
      return $('.special-rates-select').data('options').useRewardsPointsError;
  };

  return {
    init: function (el) {
      var $el = $(el);

      els = {
        component: $el,
        formGroup: $el.find('.form-group'),
        specialRatesTrigger: $el.find('.js-special-rates-trigger'),
        specialRatesPanel: $el.find('.js-special-rates-panel'),
        specialRatesSelect: $el.find('.special-rates-select'),
        screenReaderEl: $el.find('.acc-hidden'),
        rateOptions: $el.find('input[name="clusterCode"]'),
        displayText: $el.find('.display-text'),
        useRewardsPoints: $el.next('.use-points-checkbox').find('#useRewardsPoints'),
        codeContainer: $el.find('.code-container'),
        specialRatesCode: $el.find('.special-rates-code'),
        corpCode: $el.find('.special-rates-code.corp-code')
      };
      vars = {
        isMobile: window.app.isMobileWindowSize()
      };

      listen();
    },
    getUseRewardsErrorMessage: getUseRewardsErrorMessage
  };
};

$(function () {
  var $components = $('.special-rates-select');
  if ($components.length) {
    $components.each(function (i, el) {
      var specialRatesSelect = new DS.specialRatesSelect();
      specialRatesSelect.init(el);
    });
  }
});
