var DS = window.DS || {};
DS.hero = function() {
  var els = {};
  var vars = {};

  var listen = function() {
    els.playPauseButton.on({
      click: function () {
        if ($(this).hasClass('play')) {
          videojs(els.video.attr('id')).ready(function(){
            els.videoJS = this;
            els.videoJS.play();
          });
          $(this).removeClass('play').addClass('pause');
          $(this).attr('aria-label', 'pause');
        } else if ($(this).hasClass('pause')) {
          videojs(els.video.attr('id')).ready(function(){
            els.videoJS = this;
            els.videoJS.pause();
          });
          $(this).removeClass('pause').addClass('play');
          $(this).attr('aria-label', 'play');
        };
      }
    })
  };

  var supportObjectFit = function() {
    Array.from(document.querySelectorAll('.hero-component .image picture')).forEach(function (element, index) {

      if("objectFit" in document.body.style == false) {
        var imgUrl = element.getElementsByTagName('img')[0].src;
        var container = element.parentNode;
        if (imgUrl) {
          container.style.backgroundImage = 'url(' + imgUrl + ')';
          container.classList.add('custom-object-fit');
        }
      }

    });
  }

  return {
    init: function(el) {
      var $el = $(el);
      els = {
        component: $el,
        video: $el.find('.video-js'),
        playPauseButton: $el.find('.video-play-pause'),
        background: $el.find('.background')
      };

      vars = {

      }

      listen();
      supportObjectFit();
    }
  };
};

$(function() {
  if ( $('.hero-component').length > 0 ) {
    $('.hero-component').each(function(i,el) {
      var hero = new DS.hero();
      hero.init(el);
    });
  }
});
