var DS = window.DS || {};
Sugar.Date.addLocale('ar-AE', {
  'plural': true,
  'units': 'ثانية|ثواني,دقيقة|دقائق,ساعة|ساعات,يوم|أيام,أسبوع|أسابيع,شهر|أشهر,سنة|سنوات',
  'months': 'جانفي,فيفري,مارس,أفريل,ماي,جوان,جويلية,أوت,سبتمبر,أكتوبر,نوفمبر,ديسمبر',
  'weekdays': 'الأحد,الاثنين,الثلاثاء,الأربعاء,الخميس,الجمعة,السبت',
  'numerals': 'صفر,واحد|ة,اثنان,ثلاثة,أربعة,خمسة,ستة,سبعة,ثمانية,تسعة,عشرة',
  'tokens': " ",
  'short':  '{yyyy}/{MM}/{dd}',
  'medium': '{yyyy} {month} {d}',
  'long':   '{yyyy} {month} {d} {time}',
  'full':   '{weekday} {d} {month} {yyyy} {time}',
  'stamp':  '{dow} {d} {mon} {yyyy} {time}',
  'time':   '{mm}:{H}',
  'past':   '{sign} {num} {unit}',
  'future': '{sign} {num} {unit}',
  'duration': '{num} {unit}',
  'timeMarkers': ' ',
  'ampm': 'صباحا,مساء',
  'modifiers': [
    { 'name': 'day', 'src': 'أمس', 'value': -1 },
    { 'name': 'day', 'src': "اليوم", 'value': 0 },
    { 'name': 'day', 'src': 'غدا', 'value': 1 },
    { 'name': 'sign', 'src': 'منذ', 'value': -1 },
    { 'name': 'sign', 'src': "في غضون", 'value': 1 },
    { 'name': 'shift', 'src': 'الماضي|ة', 'value': -1 },
    { 'name': 'shift', 'src': 'الآتي|ة', 'value': 1 }
  ],
  'parse': [
    '{months} {year?}',
    '{sign} {num} {unit}',
    '{0?} {unit:5-7} {shift}'
  ],
  'timeParse': [
    '{day|weekday} {shift?}',
    '{weekday?},? {0?} {date}{1?} {months}\\.? {year?}'
  ],
  'timeFrontParse': [
    '{0?} {weekday} {shift}',
    '{weekday?},? {0?} {date}{1?} {months}\\.? {year?}'
  ]
});

DS.dateSelect = function() {
    var els = {};
    var vars = {};
    var getkDate = function() {
        var date = new Date(), curDate = new Date();
        // Eastern Time offset (UTC−05:00)
        // add 357 days to current day and substarct current week day from it.
        return date.setTime(curDate.getTime() + (357 * 86400000) - ((curDate.getDay()) * 86400000));
    };

    var listen = function() {
        els.component.on({
            click: function(event) {
                event.preventDefault();
                resetDatesAndRange();
                els.dateFrom.focus();
            }
        }, '.js-date-reset, .ui-datepicker-clear');

        // Hide datepicker when switching fields
        els.component.siblings().on({
            focus: toggleDatePicker
        }, 'input, button, a');

        els.component.parents('.booking-component').on({
            click: function(event) {
                event.stopPropagation();
                toggleDatePicker(event);
            }
        });

        els.dateFrom.on({
            focus: function(event) {
                var isMobile = window.app.isMobileWindowSize();
                vars.currentFocusedDateField = 'arrival_date';
                els.datePickerHolder.datepicker('option', 'minDate', 0);
                els.datePickerHolder.datepicker('option', 'maxDate', vars.bookingLimit() - 1 );
                els.datePickerHolder.datepicker('setDate', new Date(vars.arrivalDate*1000));

                if (!isMobile) {
                    toggleDatePicker(event);
                    els.datePickerHolder.insertAfter( $(this).parent() );
                }
            },
            change: function(event, _date) {
                updateArrivalDate( event, _date ? _date : $(this).val() );
            }
        });

        els.dateTo.on({
            focus: function(event) {
                var isMobile = window.app.isMobileWindowSize();
                vars.currentFocusedDateField = 'departure_date';
                els.datePickerHolder.datepicker('setDate', new Date(vars.departureDate*1000));
                els.datePickerHolder.datepicker('option', 'maxDate', vars.bookingLimit() );

                if (!isMobile) {
                    toggleDatePicker(event);
                    els.datePickerHolder.datepicker('option', 'minDate', 0);
                    els.datePickerHolder.insertAfter( $(this).parent() );
                }
            },
            change: function(event, _date) {
                updateDepartureDate( event, _date ? _date : $(this).val() );
            }
        });

        els.toggleFlexdate.on({
            click: toggleFlexDates
        });

        els.flexMonth.on({
            click: selectMonth
        });

        els.flexDisplay.on({
            focus: toggleDatePicker
        });

        els.component.on({
            click: function(event) {
                event.preventDefault();
                if($(this).hasClass('inactive')) {
                    return false;
                }

                DS.roomsGuestsSelect().stepperClickHandler(event, this);
                // Update date range in calendar view
                vars.allowCalendarRangeUpdate = false;
                updateDatepickerRange(null, null, true);

                var $el_plus = $('.js-nights').find('.js-plus');
                if (els.flexNightCount.val() >= 9) {
                    $el_plus.addClass('inactive').attr('aria-disabled', true);
                }
                else {
                    $el_plus.removeClass('inactive').attr('aria-disabled', false);
                }
            },
            change: function( event ) {
                // // TODO: Refactor stepperClickHandler into booking.js for use across date picker and room guests select
                // // and any other stepper within booking component
                // DS.roomsGuestsSelect().parseStepperValue(event, this);
                //
                // // Update date range in calendar view
                // updateFlexRange( this );
            }
        }, '.plus, .minus');

        els.numOfFlexNightsInput.on({
            change: function() {
                var nights = $(this).val();

                if (nights > 9) {
                    nights = 9
                }
                else if (nights < 1) {
                    nights = 1;
                }

                updateDatepickerRange(nights, null, true);
            }
        });
    };

    // _addDaysNum optional
    var getUnixTime = function( _date, _addDaysNum ) {
        var _d = Sugar.Date( _date );
        return Sugar.Date.format( _addDaysNum ? _d.addDays( _addDaysNum ): _d, '{X}');
    };

    var getDateForHiddenFields = function( _date, _addDaysNum ) {
        var _d = Sugar.Date( _date );
        return Sugar.Date.format( _addDaysNum ? _d.addDays( _addDaysNum ): _d, vars.dateQueryFormat ? vars.dateQueryFormat : '%m/%d/%Y');
    };

    var getDateForDisplay = function( _date, _addDaysNum ) {
        var _d = Sugar.Date( _date );
        return Sugar.Date.format( _d ? _addDaysNum ? _d.addDays( _addDaysNum ) : _d : new Date(), '%a, %b %d');
    };

    var getDateRange = function( _fromDate, _toDate ) {
        return Sugar.Date.range( _fromDate,_toDate ).days();
    };

    var selectMonth = function(event) {
        if (event) event.preventDefault();

        if ( !$(this).hasClass('js-flex-month') ) {
            // Update flex dates view month selected
            //
            if( vars.reset ) {
                vars._monthVal      = Sugar.Date.format( new Date(), '{M}');
                vars._yearVal       = Sugar.Date.format( new Date(), '{yyyy}');
                vars._dayVal        = Sugar.Date.set(new Date());
                vars._isCurMonth    = true;
            }

            var flexMonthFilter = '[data-month="'+ vars.selectedMonth +'"][data-year="'+ vars.selectedYear + '"]';
            vars.flexStartDate = Sugar.Date.set(new Date(), {month: vars.selectedMonth - 1, year: vars.selectedYear}, true);

            els.flexMonth
                .removeClass( 'is-selected' )
                .filter( flexMonthFilter )
                .addClass( 'is-selected' );

            updateDates({flexdates: false, selectedYear: vars._yearVal, selectedMonth: vars._monthVal, selectedDay: vars._isCurMonth ? vars._dayVal : 1});
        }
        else {
            els.flexMonth.removeClass('is-selected');
            $(this).addClass('is-selected');

            // Update .js-month-display
            vars._monthVal      = parseInt( $(this).data('month') - 1 );
            vars._yearVal       = parseInt( $(this).data('year') );
            vars.flexStartDate  = Sugar.Date.set(new Date(), {month: vars._monthVal, year: vars._yearVal, day: 1}, true);
            vars._isCurMonth    = Sugar.Date.isThisMonth(vars.flexStartDate);

            // Pass in today's date to the date object if is current month.
            // Else keep default to the 1st of the month.
            if (vars._isCurMonth) {
                vars._dayVal        = Sugar.Date.format(Sugar.Date.reset(new Date()), '{d}');
                vars.flexStartDate = Sugar.Date.set(new Date(), {month: vars._monthVal, year: vars._yearVal, day: vars._dayVal}, true);
            }
            // Change check-in/check-out dates to 1st and 2nd of selected month
            //
            updateDates({flexdates: true, selectedYear: vars._yearVal, selectedMonth: vars._monthVal, selectedDay: vars._isCurMonth ? vars._dayVal : 1});
            updateDatepickerRange(null, null, false);
        }
        // Update display text
        //
        els.datePickerHolder.datepicker('setDate', vars.flexStartDate);
        els.monthDisplay.text( Sugar.Date( vars.flexStartDate ).format('%B') );
    };

    var toggleDatePicker = function(event) {

        // Fix calendar not aligning properly on top level and other pages besides home page
        initDatePicker();

        if ( window.app.isMobileWindowSize() ) {
            return false;
        }

        var target = $(event.target);
        var isDatePickerToggle = target.is(els.togglePicker);
        var isPickerHeader = target.parents('.ui-datepicker-header').length;
        var isButtonPane = target.parents('.ui-datepicker-buttonpane').length;
        var datePickerIsVisible = els.datePickerHolder.is(":visible");
        var clickedOutsideOfDatepicker = !isDatePickerToggle && !isPickerHeader && !isButtonPane && els.togglePicker.has(event.target).length === 0;

            // var isValidStayLength = getDateRange( vars.dateFromVal.raw, vars.dateToVal.raw ) <= vars.stayLimit; // Less than 90 days
            // var isValidDate = getUnixTime( _departureDate.raw ) > getUnixTime( vars.minArrivalDate );

        if ( !datePickerIsVisible && !clickedOutsideOfDatepicker ) {
            // Open picker if not clicked outside
            els.datePickerHolder.slideDown('fast');
        }
        else if ( datePickerIsVisible && clickedOutsideOfDatepicker ) {
            // Close date picker when clicked outside
            els.datePickerHolder.slideUp('fast');
        }
    };

    var toggleFlexDates = function(event) {
        event.preventDefault();
        els.component.toggleClass('is-flex-hidden is-picker-hidden');
        els.flexMonthWrapper.toggle();
        els.component.find('.ui-datepicker').toggle();
        // els.resetFields.toggleClass('is-hidden');
        els.clearDates.toggleClass('is-hidden');
        els.pickerFooter.toggleClass('calendar-footer');
        els.flexdatesCheck.attr( 'checked', !els.flexdatesCheck.attr('checked') );
        vars.showFlex = !vars.showFlex;

        // vars.flexRange = els.flexNightCount.prop('selectedIndex') + 1;

        updateDatepickerRange(null, null, null);
        els.toggleFlexdate.html( vars.showFlex ? vars.pickerOptions.specificdates : vars.pickerOptions.flexdates );

        setDatePickerFocus();
    };

    var setDatePickerFocus = function() {
      var flexMonthsVisible = els.flexMonthWrapper.is(":visible");

      if ( flexMonthsVisible ) {
        var interactiveEls = els.datePickerHolder.find(':focusable');

        if( interactiveEls ) {
          interactiveEls.first().focus();
        }
      }
      else {
        els.dateFrom.focus();
      }
    };

    var updateArrivalDate = function( evt, _date ) {
        var $this = els.dateFrom;
        var _arrivalDate = Sugar.Date.create( _date );

        if ( validateDate( _arrivalDate ) ) {
            var range = getDateRange( els.arrivalDate.val(), _arrivalDate );

            var isValidStayLength = range <= vars.stayLimit || getUnixTime( _arrivalDate ) > vars.departureDate; // Less than 198 days or arrival date is after departure date
            // Arrival cannot be past kDate
            var isBeforekDate = getUnixTime( _arrivalDate ) <= getUnixTime( Sugar.Date.rewind( new Date( getkDate() ), '1 day', true ) );

            if (!isBeforekDate) {
                alert( vars.invalidDateError );
                return false;
            }

            if ( isValidStayLength ) { // Less than 198 nights
                $this.val( getDateForDisplay( _arrivalDate ) );
                els.arrivalDate.val( getDateForHiddenFields( _arrivalDate ) );

                updateDatepickerRange( _arrivalDate, $this, false);
                els.datePickerHolder.datepicker('setDate', vars.flexStartDate);
            }
            else {
                alert( vars.invalidStayLength );
                $this.val( getDateForDisplay( els.arrivalDate.val() ) );
            }
        }
        else {
            alert( vars.invalidDateError );
            $this.val( getDateForDisplay( els.arrivalDate.val() ) );
        }
    };

    var updateDepartureDate = function( evt, _date ) {
        var $this = els.dateTo;
        var _departureDate = Sugar.Date.create( _date );

        if ( validateDate( _departureDate ) ) { // If date is between today and kDate
            var range = getDateRange( els.arrivalDate.val(), _departureDate );
            var isValidStayLength = range <= vars.stayLimit || getUnixTime( _departureDate ) < vars.arrivalDate; // Less than 198 days or departure date is before arrival date

            if ( isValidStayLength ) { // Less than 198 nights
                $this.val( getDateForDisplay( _departureDate ) );
                els.departureDate.val( getDateForHiddenFields( _departureDate ) );

                updateDatepickerRange( _departureDate, $this, false);
                els.datePicker.datepicker('setDate', _departureDate);
                $this.focus();
            }
            else
            {
                alert( vars.invalidStayLength );
                $this.val( getDateForDisplay( els.departureDate.val() ) );
            }
        }
        else {
            alert( vars.invalidDateError );
            $this.val( getDateForDisplay( els.departureDate.val() ) );
        }
    };

    var resetDatesAndRange = function() {
        var arrivalDate = Sugar.Date.addDays(new Date(), 0, true);
        var departureDate = Sugar.Date.addDays(new Date(), 1, true);

        // Flex month
        vars.selectedMonth = Sugar.Date.format( arrivalDate, '{M}');
        vars.selectedYear = Sugar.Date.format( arrivalDate, '{yyyy}');

        // Unix time
        vars.arrivalDate = getUnixTime( arrivalDate );
        vars.departureDate = getUnixTime( departureDate );

        // Display dates
        vars.dateFromVal = getDateForDisplay( arrivalDate );
        vars.dateToVal = getDateForDisplay( departureDate );
        els.dateFrom.val( vars.dateFromVal );
        els.dateTo.val( vars.dateToVal );

        // Update hidden input values for search query
        els.arrivalDate.val( getDateForHiddenFields( arrivalDate ) );
        els.departureDate.val( getDateForHiddenFields( departureDate )  );
        els.datePickerHolder.datepicker("refresh");

        // reset numberOfNights
        var minNights = els.numOfFlexNightsInput.data('min');
        els.numOfFlexNightsInput.trigger('parseStepperValue', minNights);

        vars.reset = true;

        updateNightsCount();
        selectMonth();
    };

    /**
     * @param {(Number|Object|Null)} inputVal
     * @param {Object} $this current element
     * @param {Boolean} _stepper
     * @param {Boolean} _reset
     */
    var updateDatepickerRange = function( inputVal, $this, _stepper ) {
        var _date;

        if ( !$this ) {
            // If selecting flex month
            //
            vars.flexStartDate = vars._isCurMonth ? Sugar.Date.create(new Date()) : Sugar.Date.set(new Date(), {month: vars._monthVal, year: vars._yearVal, day: 1}, true);
            vars.flexRange = inputVal ? inputVal : els.flexNightCount.prop('selectedIndex') + 1;

            vars.arrivalDate = getUnixTime( vars.flexStartDate );
            vars.dateFromVal = getUnixTime( vars.flexStartDate  );
            els.dateFrom.val( getDateForDisplay( vars.flexStartDate ) ); // Display date
            els.arrivalDate.val( getDateForHiddenFields( vars.flexStartDate  ) ); // Hidden input

            vars.dateToVal = getUnixTime( vars.flexStartDate, vars.flexRange );
            vars.departureDate = getUnixTime( vars.flexStartDate );
            els.dateTo.val( getDateForDisplay( vars.flexStartDate ) ); // Display date
            els.departureDate.val( getDateForHiddenFields( vars.flexStartDate )); // Hidden input
        }
        else {
            // On date input change
            _date = Sugar.Date( inputVal );

            // Update arrival date
            if ( $this.hasClass("date-from") ) {
                if ( getUnixTime( inputVal ) < vars.minDate ) {
                    vars.arrivalDate = getUnixTime( vars.minArrivalDate  );
                    vars.dateFromVal = getUnixTime( vars.minArrivalDate  );
                    els.dateFrom.val( getDateForDisplay( vars.minArrivalDate) ); // Display date
                    els.arrivalDate.val( getDateForHiddenFields( vars.minArrivalDate ) );
                }
                else {
                    vars.flexStartDate = _date;
                    vars.dateFromVal = _date;
                    vars.arrivalDate = getUnixTime( inputVal );
                    els.arrivalDate.val( getDateForHiddenFields( inputVal ) );

                    // If arrival date is past the departure date
                    if (vars.arrivalDate >= vars.departureDate) {
                        vars.departureDate =  getUnixTime( inputVal, 1 );

                        vars.dateToVal = _date;
                        els.dateTo.val( getDateForDisplay( vars.dateToVal.raw ) );
                        els.departureDate.val( getDateForHiddenFields( vars.dateToVal.raw ) );
                    }
                }
            }
            // Update departure date
            else {
                if (getUnixTime( inputVal ) < vars.minDate) {
                    vars.departureDate =  vars.dateFromVal.addDays(1);
                    vars.dateToVal = vars.dateFromVal.addDays(1);
                    els.dateTo.val( getDateForDisplay( vars.dateFromVal.raw ) );
                    els.departureDate.val( getDateForHiddenFields( vars.dateFromVal.raw ) );
                    return false;
                }
                else {
                    vars.dateToVal = _date;
                    vars.departureDate = getUnixTime( inputVal ); // Unix timestamp
                    els.departureDate.val( getDateForHiddenFields( inputVal ) );

                    // If departure date is before arrival date
                    if (vars.departureDate <= vars.arrivalDate) {
                        vars.arrivalDate = _date.rewind('1 day').format('{X}').raw;

                        vars.dateFromVal = _date.rewind('1 day');
                        els.dateFrom.val(getDateForDisplay(vars.dateFromVal.raw));
                        els.arrivalDate.val(getDateForHiddenFields(vars.dateFromVal.raw));
                    }
                }
            }
        }

        els.datePickerHolder.datepicker("refresh");
        validateFlexRangeWithkDate();
        updateNightsCount();
    };

    var validateFlexRangeWithkDate = function () {
        var d = getUnixTime( els.departureDate.val() );
        var k = Sugar.Date.rewind( new Date( getkDate() ), '0 day', true );

        if ( d >= getUnixTime(k) ) {
            vars.dateToVal = k;
            vars.departureDate = getUnixTime( k );
            els.dateTo.val( getDateForDisplay( k ) );
            els.departureDate.val( getDateForHiddenFields( k ) );
            $('.js-nights').find('.js-plus').addClass('inactive');
        }
        else if ( vars.stayLength < 9 ) {
            $('.js-plus').prop('disabled', false).removeClass('inactive');
        }
    };

    var updateNightsCount = function() {
        vars.stayLength = getDateRange( els.arrivalDate.val(), els.departureDate.val() );
        var label = $('body').find('.stay-length-count').data( vars.stayLength > 1 ? 'plural' : 'singular');
        $('body').find('.stay-length-count').text( vars.stayLength+' '+label  );
    };

    /**
     * @param {Object} _date
     */
    var validateDate = function( _date ) {
        var inputDate = Sugar.Date.create( _date );
        return Sugar.Date.isBetween( inputDate, vars.minArrivalDate, Sugar.Date.create( getkDate() ) );
    };

    var setRange = function( date ) {
        var a = vars.arrivalDate; // Unix Time
        var d = vars.departureDate; // Unix Time
        var time = date.getTime()/1000;
        var greaterThanToday = time > new Date();
        var lessThanMin = time < vars.arrivalDate;
        var minRange = time === Math.min(a, d);
        var maxRange = time === Math.max(a, d);
        var inBetweenRange = time > Math.min(vars.arrivalDate, vars.departureDate) && time <= Math.max(vars.arrivalDate, vars.departureDate);

        vars.flexRange = Sugar.Date.range(vars.dateFromVal, vars.dateToVal).days();
        if ( vars.flexRange > 9 ) {
            els.flexNightCount.find('option').eq(9).prop('selected', true);
            vars.flexRange = 9;
        }
        else {
            els.flexNightCount.find('option').eq(vars.flexRange - 1).prop('selected', true);
        }

        if ( vars.allowCalendarRangeUpdate && !minRange && !maxRange && vars.flexRange <= 9 && vars.flexRange >= 0 ) {
            els.numOfFlexNightsInput.val( vars.flexRange );

            DS.roomsGuestsSelect().parseStepperValue( els.numOfFlexNightsInput, vars.flexRange );
        }

        if (vars.currentFocusedDateField !== 'arrival_date') {
            if (lessThanMin && greaterThanToday) {
                return [true, 'ui-state-disabled']
            }
        }
        if (minRange) {
            return [true, 'date-range-min-date']
        }
        if (maxRange) {
            return [true, 'date-range-max-date']
        }

        return [true, ( inBetweenRange ? 'date-range-selected' : '')];
    };

    var setDefaultDates = function() {
        var d1_QueryStringFormat, d2_QueryStringFormat;
        var defaultArrivalDate = Sugar.Date.addDays(new Date(), 0, true);
        var defaultDepartureDate = Sugar.Date.addDays(new Date(), 1, true);

        vars.dateFromVal = defaultArrivalDate;
        vars.dateToVal = defaultDepartureDate;

        vars.arrivalDate = getUnixTime( defaultArrivalDate );
        vars.departureDate = getUnixTime( defaultDepartureDate );

        els.dateFrom.val( getDateForDisplay( defaultArrivalDate ) );
        els.dateTo.val( getDateForDisplay( defaultDepartureDate ) );

        d1_QueryStringFormat = getDateForHiddenFields( defaultArrivalDate );
        d2_QueryStringFormat = getDateForHiddenFields( defaultDepartureDate );

        els.arrivalDate.val( d1_QueryStringFormat );
        els.departureDate.val( d2_QueryStringFormat );

        els.staylengthCount.text( 1 + ' ' + els.staylengthCount.data('singular') );

        vars._isCurMonth = Sugar.Date.isThisMonth( defaultArrivalDate );
        vars.flexStartDate = Sugar.Date.set( defaultArrivalDate );
    };

    var updateDates = function (inst, dateText) {
        var d1, d2, d1_QueryStringFormat, d2_QueryStringFormat;
        var selectedDate = (new Date(inst.selectedYear, inst.selectedMonth, inst.selectedDay));

        // Reset flex nights input
        vars.allowCalendarRangeUpdate = true;
        vars._isCurMonth = Sugar.Date.isThisMonth( selectedDate );

        if ( inst.flexdates ) {
            els.dateFrom.val( getDateForDisplay( vars.flexStartDate ) ); // Display date
            els.dateTo.val( getDateForDisplay( vars.flexStartDate , vars.flexRange ) ); // Display date
            //
            vars.dateFromVal = getUnixTime( vars.flexStartDate  );
            vars.dateToVal = getUnixTime( vars.flexStartDate , vars.flexRange );

            els.arrivalDate.val( getDateForHiddenFields( vars.flexStartDate  ) ); // Hidden input
            els.departureDate.val( getDateForHiddenFields( vars.flexStartDate , vars.flexRange ) ); // Hidden input
        }
        else {
            var _year, _month;


            if (inst.selectedYear && inst.selectedMonth >= 0 && !vars.reset) {
                _year = inst.selectedYear;
                _month = inst.selectedMonth;
            }
            else {
                _year = parseInt( Sugar.Date.format( new Date(), '{yyyy}') ); // current year
                _month = parseInt( Sugar.Date.format( new Date(), '{M}') ); // current month
                selectedDate = Sugar.Date.set(new Date());
            }


            // For flex dates month highlight
            vars.selectedMonth = Sugar.Date.format(new Date(_year,_month), '{M}');
            vars.selectedYear  = Sugar.Date.format(new Date(_year,_month), '{yyyy}');


            if ( vars.currentFocusedDateField === 'arrival_date' ) {
                els.datePickerHolder.datepicker('setDate', selectedDate );
                els.datePickerHolder.datepicker('option', 'minDate', 0);
                vars.arrivalDate = getUnixTime( selectedDate );
            }

            try {
                if (Sugar.Date(selectedDate).isValid()) {
                    vars.departureDate = getUnixTime(selectedDate);
                }
            }
            catch (err) {
                // handle error
            }


            if ( vars.arrivalDate >= vars.departureDate || vars.reset ) {
                vars.departureDate = getUnixTime( vars.arrivalDate*1000, 1 ); // Get unix time and add 1 additional day
            }

            var minDate = new Date( Math.min(vars.arrivalDate, vars.departureDate) );
            var maxDate = new Date( Math.max(vars.arrivalDate, vars.departureDate) );

            d1 = getDateForDisplay( minDate*1000 );
            d2 = getDateForDisplay( maxDate*1000 );
            d1_QueryStringFormat = getDateForHiddenFields( minDate*1000 );
            d2_QueryStringFormat = getDateForHiddenFields( maxDate*1000 );
            var range = getDateRange(d1_QueryStringFormat, d2_QueryStringFormat);

            // Limit datepicker to 198 days or less from today's date
            if (range > vars.stayLimit) {
                alert( vars.invalidStayLength );
                d2 = getDateForDisplay( minDate*1000, vars.stayLimit );
                d2_QueryStringFormat = getDateForHiddenFields( minDate*1000, vars.stayLimit );
            }

            els.dateFrom.val( d1 );
            els.dateTo.val( d2 );
            els.arrivalDate.val( d1_QueryStringFormat );
            els.departureDate.val( d2_QueryStringFormat );

            vars.dateFromVal = d1;
            vars.dateToVal = d2;

            els.win.trigger('datepickerSelect');
            els.dateFrom.trigger('change', d1_QueryStringFormat);
            els.dateTo.trigger('change', d2_QueryStringFormat);
        }

        if ( vars.currentFocusedDateField === 'arrival_date' && !inst.flexdates && !vars.reset ) {
            vars._monthVal = inst.currentMonth;
            vars._yearVal = inst.currentYear;

            els.dateTo.focus();
            selectMonth();

            vars.reset = false;
        }
    };

    var appendClearDoneButtons = function() {
        if ( !els.datePickerHolder.find('.ui-datepicker-clear').length || !els.datePickerHolder.find('.ui-datepicker-close').length ) {
            var clearText = els.datePickerHolder.data('label-clear-btn');
            var doneText = els.datePickerHolder.data('label-done-btn');

            $('<button type="button" class="ui-datepicker-clear ui-state-default ui-priority-primary ui-corner-all" data-handler="hide" data-event="click">' + clearText + '</button>')
                .appendTo(els.datePickerHolder.find('.ui-datepicker-buttonpane'));

            $('<button type="button" class="ui-datepicker-close ui-state-default ui-priority-primary ui-corner-all" data-handler="hide" data-event="click">' + doneText + '</button>')
                .appendTo(els.datePickerHolder.find('.ui-datepicker-buttonpane'));
        }
    };

    var initDatePicker = function() {
        $.datepicker._defaults.onAfterUpdate = null;
        var datepicker__updateDatepicker = $.datepicker._updateDatepicker;

        $.datepicker._updateDatepicker = function( inst ) {
            datepicker__updateDatepicker.call( this, inst );

            var onAfterUpdate = this._get(inst, 'onAfterUpdate');
            if (onAfterUpdate)
                onAfterUpdate.apply((inst.input ? inst.input[0] : null),
                    [(inst.input ? inst.input.val() : ''), inst]);
        };

        // Create the picker and align it to the bottom of the input field
        // Hide it for later
        els.datePickerHolder.datepicker({
            defaultDate: 0,
            showButtonPanel: false,
            numberOfMonths: vars.isMobile ? (Sugar.Date.monthsUntil( new Date(), getkDate() ) + 2) : 1,
            hideIfNoPrevNext: !!vars.isMobile,
            dateFormat: 'D, M dd yy',
            autoSize: true,
            minDate: 0,
            maxDate: vars.bookingLimit(),
            nextText: els.datePickerHolder.data('label-next-month'),
            prevText: els.datePickerHolder.data('label-prev-month'),
            beforeShow: function() {
                setDefaultDates();
            },
            beforeShowDay: function ( date ) {
                // Highlight dates within min and max range
                return setRange( date );
            },
            onSelect: function(dateText, inst) {
                if ( Sugar.Date( dateText ) ) {
                    vars.reset = false;
                    updateDates(inst, dateText);
                    updateNightsCount();
                }
            },
            onAfterUpdate: function ( inst ) {
                $('.booking-component .warning').fadeOut(250);
                els.defaultActiveDate.removeClass('ui-state-active');
                var nextPrevAttr = {
                    'href': '#',
                    'tabindex': 0,
                    'disabled': function() {
                        return $(this).hasClass('ui-state-disabled') ? 'disabled': '';
                    }
                };

                // $('.ui-datepicker-today')
                //   .addClass('ui-state-disabled')
                //   .removeAttr('data-handler data-event')
                //   .find('a').replaceWith(function(){
                //     return $("<span>" + $(this).html() + "</span>");
                //   });

                $('.current-month-title').html( $('.ui-datepicker-title').html() );

                // Next/Prev month loses focus after click because the dickpicker refreshes
                // Adding a slight timout to refocus for accessibility
                setTimeout(function(){
                    if (els.monthNavLastClicked) {
                        if (els.monthNavLastClicked.hasClass('ui-datepicker-next')) {
                            $('.ui-datepicker-next').focus();
                        }
                        else {
                            $('.ui-datepicker-prev').focus();
                        }
                    }
                },100);


                $('.ui-datepicker-header a').on({
                    click: function(evt){
                        evt.preventDefault();
                        els.monthNavLastClicked = $(this);
                    },
                    blur: function() {
                        els.monthNavLastClicked = null;
                    }
                });

                $('.ui-datepicker-prev').attr(nextPrevAttr);
                $('.ui-datepicker-next').attr(nextPrevAttr);

                if ( !vars.isMobile ) {
                    appendClearDoneButtons();
                }
            }
        })
        .css({
            top: els.component.innerHeight()
        });
    };

    var getFlexdatesErrorMessage = function() {
        return $('.date-select').data('options').flexdatesError;
    };

    var localizeFlexmonths = function() {
        var currentMonth = Sugar.Date.reset(new Date(), 'month');
        var _initialMonthName = Sugar.Date.format( currentMonth, '%B' );
        els.monthDisplay.text( _initialMonthName );

        els.flexMonth.each( function(i){
            var _month = this.dataset.month;
            var _year = this.dataset.year;
            var translatedDate = Sugar.Date.create( _year + '-' + _month + '-01');
            var translatedMonthShort = Sugar.Date.format(translatedDate, '%b');
            var translatedMonthFull = Sugar.Date.format(translatedDate, '%B');
            var translatedYear = Sugar.Date.format(translatedDate, '%Y');

            // Hide flex month if greater than kDate
            if ( getUnixTime( translatedDate ) > getUnixTime( getkDate() ) ) {
                $(this).hide();
            }

            if ( translatedMonthShort === 'Sept') {
                translatedMonthShort = 'Sep';
            }

            $(this).find('.flex-month-label').text( i === 0 || parseInt(_month) === 1 ? translatedMonthShort + ' ' + translatedYear : translatedMonthShort );
            $(this).attr('aria-label', translatedMonthFull + ' ' + translatedYear );
         });
    };

    return {
        init: function(el) {
            var lang = $('html')[0].lang;
            var $el = $(el);
            var options = $el.data('options');

            els = {
                win: $(window),
                component: $el,
                datePickerHolder: $el.find('.picker-holder'),
                datePicker: $el.find('.ui-datepicker'),
                defaultActiveDate: $el.find('.ui-state-default'),
                dateRange: $el.find('.date-range'),
                arrivalDate: $el.find('.arrival-date'),
                departureDate: $el.find('.departure-date'),
                staylengthCount: $el.find('.stay-length-count'),

                clearDatesBtn: $el.find('.ui-datepicker-clear'),
                closeDatePicker: $el.find('.ui-datepicker-close'),
                togglePicker: $el.find('.js-toggle-date-picker'),
                dateFrom: $el.find('.date-from'),
                dateTo: $el.find('.date-to'),
                warningStayLimit: $el.find('.warning-stay-limit'),
                warningBookingLimit: $el.find('.warning-booking-limit'),
                prevMonthBtn: $el.find('.ui-datepicker-prev'),
                nextMonthBtn: $el.find('.ui-datepicker-next'),

                pickerFooter: $el.find('.picker-footer'),
                flexMonthWrapper: $el.find('.flex-month-wrapper'),
                toggleFlexdate: $el.find('.js-toggle-flexdate'),
                clearDates: $el.find('.js-date-clear, .ui-datepicker-clear'),
                resetFields: $el.find('.js-date-reset'),
                flexdatesCheck: $el.find('.js-flex-check'),
                flexMonth: $el.find('.js-flex-month'),
                flexMonthLabel: $el.find('.flex-month-label'),
                flexDisplay: $el.find('.js-flex-display'),
                flexNightCount: $el.find('.nightCount'),
                monthDisplay: $el.find('.js-month-display'),
                numOfFlexNightsInput: $el.find('.js-night-num')
            };
            vars = {
                stayLimit: 198,
                bookingLimit: function() {
                    return Sugar.Date.range( new Date(), getkDate() ).days();
                },
                stayLength: 0,
                isMobile: window.app.isMobileWindowSize(),
                isChildOfBookingComponent: els.component.parents('.booking-component').length,

                dateFromVal: null,
                dateToVal: null,
                departureDate: -1,
                arrivalDate: -1,
                currentFocusedDateField: null,

                minDate: Sugar.Date.format(new Date(), '{X}'),
                minArrivalDate: Sugar.Date.create(new Date().setHours(0,0,0,0)),

                showFlex: false,
                flexRange: 1,
                pickerOptions: options,
                lengthOfStayMin: options.lengthOfStayMin,
                lengthOfStayMax: options.lengthOfStayMax,
                invalidStayLength: options.invalidStayLength,
                invalidDateError: options.invalidDateError
            };

            switch (lang) {
                case 'ar-AE':
                    Sugar.Date.setLocale('ar-AE');
                    vars.dateQueryFormat = '%d-%m-%Y';
                    $.datepicker.setDefaults( $.datepicker.regional[ 'ar-AE' ] );
                    break;
                case 'pt-br':
                    Sugar.Date.setLocale('pt');
                    vars.dateQueryFormat = '%d/%m/%Y';
                    $.datepicker.setDefaults( $.datepicker.regional[ 'pt-BR' ] );
                    break;
                case 'zh-CN':
                    Sugar.Date.setLocale('zh-CN');
                    vars.dateQueryFormat = '%Y-%m-%d';
                    $.datepicker.setDefaults( $.datepicker.regional[ 'zh-CN' ] );
                    break;
                case 'de-DE':
                    Sugar.Date.setLocale('de');
                    vars.dateQueryFormat = '%d.%m.%Y';
                    $.datepicker.setDefaults( $.datepicker.regional.de );
                    break;
                case 'fr-FR':
                    Sugar.Date.setLocale('fr');
                    vars.dateQueryFormat = '%d/%m/%Y';
                    $.datepicker.setDefaults( $.datepicker.regional.fr );
                    break;
                case 'it-IT':
                    Sugar.Date.setLocale('it');
                    vars.dateQueryFormat = '%d/%m/%Y';
                    $.datepicker.setDefaults( $.datepicker.regional.it );
                    break;
                case 'ja-JP':
                    Sugar.Date.setLocale('ja');
                    vars.dateQueryFormat = '%Y/%m/%d'; //yyyy/mm/dd
                    $.datepicker.setDefaults( $.datepicker.regional.ja );
                    break;
                case 'ru-RU':
                    Sugar.Date.setLocale('ru');
                    vars.dateQueryFormat = '%d.%m.%Y';
                    $.datepicker.setDefaults( $.datepicker.regional.ru );
                    break;
                case 'es-XM':
                    Sugar.Date.setLocale('es');
                    vars.dateQueryFormat = '%d/%m/%Y';
                    $.datepicker.setDefaults( $.datepicker.regional.es );
                    break;
                default:
                    $.datepicker.setDefaults( $.datepicker.regional[ '' ] );
            }

            setDefaultDates();
            initDatePicker();

            if ( !vars.isMobile ) {
                els.component.find('input').removeAttr('readonly');
                els.pickerFooter.appendTo(els.datePickerHolder);
            }

            localizeFlexmonths();
            listen();
        },
        getFlexdatesErrorMessage: getFlexdatesErrorMessage,
        updateDatepickerRange: updateDatepickerRange,
        updateNightsCount:updateNightsCount
    };
};

$(function() {
    var $components = $('.date-select');
    if ( $components.length ) {
        $components.each(function(i,el) {
            var mrtt_datepicker = new DS.dateSelect();
            mrtt_datepicker.init(el);
        });
    }
});






// Translations
//
(function (e) {
    "function" == typeof define && define.amd ? define(["jquery"], e) : e(jQuery)
})(function (e) {
    var datepicker = e.datepicker;

    datepicker.regional[ "ar-AE" ] = {
      closeText: "إغلاق",
      prevText: "السابق&#x3C;",
      nextText: "&#x3E;التالي",
      currentText: "اليوم",
      monthNames: [ "يناير", "فبراير", "مارس", "أبريل", "مايو", "يونيو",
      "يوليو", "أغسطس", "سبتمبر", "أكتوبر", "نوفمبر", "ديسمبر" ],
      monthNamesShort: [ "يناير", "فبراير", "مارس", "أبريل", "مايو", "يونيو",
      "يوليو", "أغسطس", "سبتمبر", "أكتوبر", "نوفمبر", "ديسمبر" ],
      dayNames: [ "الأحد", "الاثنين", "الثلاثاء", "الأربعاء", "الخميس", "الجمعة", "السبت" ],
      dayNamesShort: [ "الأحد", "الاثنين", "الثلاثاء", "الأربعاء", "الخميس", "الجمعة", "السبت" ],
      dayNamesMin: [ "الأحد", "الاثنين", "الثلاثاء", "الأربعاء", "الخميس", "الجمعة", "السبت" ],
      weekHeader: "أسبوع",
      dateFormat: "dd/mm/yy",
      firstDay: 0,
      isRTL: true,
      showMonthAfterYear: false,
      yearSuffix: ""
  },
      datepicker.regional[ "ar-AE" ],

    /* Brazilian initialisation for the jQuery UI date picker plugin. */
    datepicker.regional[ "pt-BR" ] = {
        closeText: "Fechar",
        prevText: "&#x3C;Anterior",
        nextText: "Próximo&#x3E;",
        currentText: "Hoje",
        monthNames: [ "Janeiro","Fevereiro","Março","Abril","Maio","Junho",
            "Julho","Agosto","Setembro","Outubro","Novembro","Dezembro" ],
        monthNamesShort: [ "Jan","Fev","Mar","Abr","Mai","Jun",
            "Jul","Ago","Set","Out","Nov","Dez" ],
        dayNames: [
            "Domingo",
            "Segunda-feira",
            "Terça-feira",
            "Quarta-feira",
            "Quinta-feira",
            "Sexta-feira",
            "Sábado"
        ],
        dayNamesShort: [ "Dom","Seg","Ter","Qua","Qui","Sex","Sáb" ],
        dayNamesMin: [ "Dom","Seg","Ter","Qua","Qui","Sex","Sáb" ],
        weekHeader: "Sm",
        dateFormat: "dd/mm/yy",
        firstDay: 0,
        isRTL: false,
        showMonthAfterYear: false,
        yearSuffix: ""
    },
        datepicker.regional[ "pt-BR" ],

        /* Russian (UTF-8) initialisation for the jQuery UI date picker plugin. */
        datepicker.regional.ru = {
            closeText: "Закрыть",
            prevText: "&#x3C;Пред",
            nextText: "След&#x3E;",
            currentText: "Сегодня",
            monthNames: [ "Январь","Февраль","Март","Апрель","Май","Июнь",
                "Июль","Август","Сентябрь","Октябрь","Ноябрь","Декабрь" ],
            monthNamesShort: [ "Янв","Фев","Мар","Апр","Май","Июн",
                "Июл","Авг","Сен","Окт","Ноя","Дек" ],
            dayNames: [ "воскресенье","понедельник","вторник","среда","четверг","пятница","суббота" ],
            dayNamesShort: [ "вск","пнд","втр","срд","чтв","птн","сбт" ],
            dayNamesMin: [ "Вс","Пн","Вт","Ср","Чт","Пт","Сб" ],
            weekHeader: "Нед",
            dateFormat: "dd.mm.yy",
            firstDay: 1,
            isRTL: false,
            showMonthAfterYear: false,
            yearSuffix: ""
        },
        datepicker.regional.ru,

        /* Chinese initialisation for the jQuery UI date picker plugin. */
        datepicker.regional[ "zh-CN" ] = {
            closeText: "关闭",
            prevText: "&#x3C;上月",
            nextText: "下月&#x3E;",
            currentText: "今天",
            monthNames: [ "一月","二月","三月","四月","五月","六月",
                "七月","八月","九月","十月","十一月","十二月" ],
            monthNamesShort: [ "一月","二月","三月","四月","五月","六月",
                "七月","八月","九月","十月","十一月","十二月" ],
            dayNames: [ "星期日","星期一","星期二","星期三","星期四","星期五","星期六" ],
            dayNamesShort: [ "周日","周一","周二","周三","周四","周五","周六" ],
            dayNamesMin: [ "日","一","二","三","四","五","六" ],
            weekHeader: "周",
            dateFormat: "yy-mm-dd",
            firstDay: 1,
            isRTL: false,
            showMonthAfterYear: true,
            yearSuffix: "年"
        },
        datepicker.regional[ "zh-CN" ],

        /* German initialisation for the jQuery UI date picker plugin. */
        datepicker.regional.de = {
            closeText: "Schließen",
            prevText: "&#x3C;Zurück",
            nextText: "Vor&#x3E;",
            currentText: "Heute",
            monthNames: [ "Januar","Februar","März","April","Mai","Juni",
                "Juli","August","September","Oktober","November","Dezember" ],
            monthNamesShort: [ "Jan","Feb","Mär","Apr","Mai","Jun",
                "Jul","Aug","Sep","Okt","Nov","Dez" ],
            dayNames: [ "Sonntag","Montag","Dienstag","Mittwoch","Donnerstag","Freitag","Samstag" ],
            dayNamesShort: [ "So","Mo","Di","Mi","Do","Fr","Sa" ],
            dayNamesMin: [ "So","Mo","Di","Mi","Do","Fr","Sa" ],
            weekHeader: "KW",
            dateFormat: "dd.mm.yy",
            firstDay: 1,
            isRTL: false,
            showMonthAfterYear: false,
            yearSuffix: ""
        },
        datepicker.regional.de,

        /* French initialisation for the jQuery UI date picker plugin. */
        datepicker.regional.fr = {
            closeText: "Fermer",
            prevText: "Précédent",
            nextText: "Suivant",
            currentText: "Aujourd'hui",
            monthNames: [ "janvier", "février", "mars", "avril", "mai", "juin",
                "juillet", "août", "septembre", "octobre", "novembre", "décembre" ],
            monthNamesShort: [ "janv.", "févr.", "mars", "avr.", "mai", "juin",
                "juil.", "août", "sept.", "oct.", "nov.", "déc." ],
            dayNames: [ "dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi" ],
            dayNamesShort: [ "dim.", "lun.", "mar.", "mer.", "jeu.", "ven.", "sam." ],
            dayNamesMin: [ "D","L","M","M","J","V","S" ],
            weekHeader: "Sem.",
            dateFormat: "dd/mm/yy",
            firstDay: 1,
            isRTL: false,
            showMonthAfterYear: false,
            yearSuffix: ""
        },
        datepicker.regional.fr,

        /* Italian initialisation for the jQuery UI date picker plugin. */
        datepicker.regional.it = {
            closeText: "Chiudi",
            prevText: "&#x3C;Prec",
            nextText: "Succ&#x3E;",
            currentText: "Oggi",
            monthNames: [ "Gennaio","Febbraio","Marzo","Aprile","Maggio","Giugno",
                "Luglio","Agosto","Settembre","Ottobre","Novembre","Dicembre" ],
            monthNamesShort: [ "Gen","Feb","Mar","Apr","Mag","Giu",
                "Lug","Ago","Set","Ott","Nov","Dic" ],
            dayNames: [ "Domenica","Lunedì","Martedì","Mercoledì","Giovedì","Venerdì","Sabato" ],
            dayNamesShort: [ "Dom","Lun","Mar","Mer","Gio","Ven","Sab" ],
            dayNamesMin: [ "Do","Lu","Ma","Me","Gi","Ve","Sa" ],
            weekHeader: "Sm",
            dateFormat: "dd/mm/yy",
            firstDay: 1,
            isRTL: false,
            showMonthAfterYear: false,
            yearSuffix: ""
        },
        datepicker.regional.it,

        /* Japanese initialisation for the jQuery UI date picker plugin. */
        datepicker.regional.ja = {
            closeText: "閉じる",
            prevText: "&#x3C;前",
            nextText: "次&#x3E;",
            currentText: "今日",
            monthNames: [ "1月","2月","3月","4月","5月","6月",
                "7月","8月","9月","10月","11月","12月" ],
            monthNamesShort: [ "1月","2月","3月","4月","5月","6月",
                "7月","8月","9月","10月","11月","12月" ],
            dayNames: [ "日曜日","月曜日","火曜日","水曜日","木曜日","金曜日","土曜日" ],
            dayNamesShort: [ "日","月","火","水","木","金","土" ],
            dayNamesMin: [ "日","月","火","水","木","金","土" ],
            weekHeader: "週",
            dateFormat: "yy/mm/dd",
            firstDay: 0,
            isRTL: false,
            showMonthAfterYear: true,
            yearSuffix: "年"
        },
        datepicker.regional.ja,

        /* Inicialización en español para la extensión 'UI date picker' para jQuery. */
        datepicker.regional.es = {
            closeText: "Cerrar",
            prevText: "&#x3C;Ant",
            nextText: "Sig&#x3E;",
            currentText: "Hoy",
            monthNames: [ "enero","febrero","marzo","abril","mayo","junio",
                "julio","agosto","septiembre","octubre","noviembre","diciembre" ],
            monthNamesShort: [ "ene","feb","mar","abr","may","jun",
                "jul","ago","sep","oct","nov","dic" ],
            dayNames: [ "domingo","lunes","martes","miércoles","jueves","viernes","sábado" ],
            dayNamesShort: [ "dom","lun","mar","mié","jue","vie","sáb" ],
            dayNamesMin: [ "D","L","M","X","J","V","S" ],
            weekHeader: "Sm",
            dateFormat: "dd/mm/yy",
            firstDay: 1,
            isRTL: false,
            showMonthAfterYear: false,
            yearSuffix: ""
        },
        datepicker.regional.es,

/* Dutch (UTF-8) initialisation for the jQuery UI date picker plugin. */
        datepicker.regional.nl = {
          closeText: "Sluiten",
          prevText: "&#x3C;Vorig",
          nextText: "Volgende&#x3E;",
          currentText: "Vandaag",
          monthNames: [ "januari", "februari", "maart", "april", "mei", "juni",
          "juli", "augustus", "september", "oktober", "november", "december" ],
          monthNamesShort: [ "jan", "feb", "mrt", "apr", "mei", "jun",
          "jul", "aug", "sep", "okt", "nov", "dec" ],
          dayNames: [ "zondag", "maandag", "dinsdag", "woensdag", "donderdag", "vrijdag", "zaterdag" ],
          dayNamesShort: [ "zon", "maa", "din", "woe", "don", "vri", "zat" ],
          dayNamesMin: [ "zo", "ma", "di", "wo", "do", "vr", "za" ],
          weekHeader: "Wk",
          dateFormat: "dd-mm-yy",
          firstDay: 1,
          isRTL: false,
          showMonthAfterYear: false,
          yearSuffix: ""
      },
      datepicker.regional.nl
});
