var DS = window.DS || {};
DS.deepLink = function() {
  var els = {};

  var checkURL = function() {
    var urlHashString = window.location.hash.replace('#','');
    var componentHashString = els.component.attr('data-deep-link-string');
    if(urlHashString === componentHashString) {
      $( window ).on( "load", function(e) {
        e.preventDefault();
        $('html, body').animate({
          scrollTop: els.component.offset().top-50
        }, 200);
      });
    }
  };

  return {
    // Adds component with data-deep-link-string to object
    // Calls checkURL() function...
    init: function(el) {
      var $el = $(el);
      els = {
        component: $el
      };
      checkURL(); 
    }
  };
};
// Calls function that adds each component with 'data-deep-link-string' to window as deepLink object
$(function() {
  $('[data-deep-link-string]').each(function(i,el) {
    var deepLink = new DS.deepLink();
    deepLink.init(el);
  });
});
