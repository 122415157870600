var DS = window.DS || {};
DS.video = function() {
    var els = {};

    var init = function(el) {
        var $el = $(el);
        els = {
            component: $el,
            playButton: $el.find('.vjs-big-play-button'),
            video: $el.find('.video-js'),
            logo: $el.find('.logo'),
            copy: $el.find('.copy-container')
        };

        // if ( !window.app.isMobile() && (els.copy.length || els.logo.length) ) {
        //     $('<div/>', {class: 'info-container'}).appendTo(els.video).append(els.logo, els.copy, els.playButton);
        // }
    };

    return {
        init: init
    };
};

$(function() {
    $('.video-component').each(function(i,el) {
        var video = new DS.video();
        video.init(el);
    });

     // Create array for player IDs
     var players = [];

     // +++  Determine the available player IDs +++//
     for (x = 0; x < Object.keys(videojs.players).length; x++) {
       // Assign the player name to setPlayer
       var setPlayer = Object.keys(videojs.players)[x];
       // Define the ready event for the player
       videojs.getPlayer(setPlayer).ready(function() {
         // Assign this player to a variable
         myPlayer = this;
         // Assign and event listener for play event
         myPlayer.on("play", onPlay);
         // Push the player to the players array
         players.push(myPlayer);
       });
     }

     // +++ Handle all players' play event +++//
     function onPlay(e) {
       // Determine which player the event is coming from
       var id = e.target.id;
       // Loop through the array of players
       for (var i = 0; i < players.length; i++) {
         // Get the player(s) that did not trigger the play event
         if (players[i].id() != id) {
           // Pause the other player(s)
           videojs(players[i].id()).pause();
         }
       }
     }

  });
