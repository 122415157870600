var DS = window.DS || {};
DS.carouselMobile = function() {
  var els = {};

  var buildCarousel = function() {

    var isRTL = document.dir == 'rtl';
    console.log('isRTL: '+isRTL);

    var slickOptions = {
      arrows:true,
      infinite:false,
      nextArrow: '<div class="carousel-arrow carousel-arrow-next icon-carret" aria-label="'+els.nextLabel+'"></div>',
      prevArrow: '<div class="carousel-arrow carousel-arrow-prev icon-carret" aria-label="'+els.previousLabel+'"></div>',
      appendArrows: els.component,
      dots: false,
      slidesToShow:1,
      waitForAnimate:false,
      slide: '.slide-mobile',
      accessibility: false,
      rtl: isRTL,
    };

    if(els.component.hasClass('show-dots')) {
      slickOptions.dots = true;
    }

    els.component.find('img').on('load', function(){
      els.slides.slick('resize');
    });

    els.slides.not('.slick-initialized').slick(slickOptions);
  };

  var destroyCarousel = function() {
    if (els.slides.hasClass('slick-initialized')) {
      els.slides.slick('unslick');
    }
  };

  var updateSlideCount = function() {
    els.slides.on('init reInit afterChange', function(event,slick, currentSlide, nextSlide) {
      var i = (currentSlide ? currentSlide : 0) + 1;
      els.counter.text(i + '/' + slick.slideCount);
    });
  };

  return {
    init: function(el) {
      var $el = $(el);
      els = {
        component: $el,
        slides : $el.find('.slides-mobile'),
        counter : $el.find('.slide-count'),
        counterContainer: $el.find('.slide-count-container'),
        previousLabel: $el.data('previous-label'),
        nextLabel: $el.data('next-label')
      };
      if (window.innerWidth <= 768) {
        updateSlideCount();
        buildCarousel();
      }
      window.addEventListener('resize', function () {
        if (window.innerWidth <= 768 & !els.slides.hasClass('slick-initialized')) {
          updateSlideCount();
          buildCarousel();
        } else if (window.innerWidth > 768 & els.slides.hasClass('slick-initialized') & !els.slides.parents('.multi-block.carousel-component')) {
          destroyCarousel();
        }
      });
    }
  };
};

$(function() {
  if ( $('.carousel-mobile-component').length > 0 ) {
    $('.carousel-mobile-component').each(function(i,el) {
      var carouselMobile = new DS.carouselMobile();
      carouselMobile.init(el);
    });
  }
});
