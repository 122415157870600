(function(window, factory) {
  // universal module definition
  /* jshint strict: false */ /*globals define, module, require */
  if (typeof define === 'function' && define.amd) {
    // AMD
    define(['isotope/js/layout-mode'], factory);
  } else if ( typeof exports === 'object' ) {
    // CommonJS
    module.exports = factory(
      require('isotope-layout/js/layout-mode')
    );
  } else {
    // browser global
    factory(window.Isotope.LayoutMode);
  }
}(window, function factory(LayoutMode) {
  'use strict';

  var SpineAlign = LayoutMode.create('spineAlign');
  var proto = SpineAlign.prototype;

  proto._resetLayout = function () {
    this.itemIndex = 0;
    this.cols = 2;
    this.col1Height = this.options.col1Height || 0;
    this.col2Height = this.options.col2Height || 0;
  };

  proto._getItemLayoutPosition = function (item) {
    item.getSize();
    var col = this.itemIndex % this.cols;
    var x = col === 0
      ? (this.isotope.size.outerWidth / 2) - item.size.outerWidth
      : (this.isotope.size.outerWidth / 2);
    var y = col === 0 ? this.col1Height : this.col2Height;
    this.itemIndex++;
    if (col === 0) {
      this.col1Height += item.size.outerHeight;
    } else {
      this.col2Height += item.size.outerHeight;
    }
    return {
      x: x,
      y: y
    };
  };

  proto._getContainerSize = function (data) {
    return {
      height: Math.max(this.col1Height, this.col2Height)
    };
  };

  return SpineAlign;
}));
