var DS = window.DS || {};
DS.stripSlideShow = function () {
  var els = {};

  var initSlick = function () {
    var slideCount = els.carousel.find('.slide-count-container').data('slide-count');

    var isRTL = document.dir == 'rtl';
    console.log('isRTL: '+isRTL);

    var slickOptions = {
      slide: '.slide',
      arrows: false,
      infinite: true,
      dots: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      waitForAnimate: false,
      accessibility: false,
      autoplaySpeed: 3000,
      pauseOnHover: false,
      pauseOnFocus: false,
      rtl: isRTL,
    }
    els.slides.not('.slick-initialized').slick(slickOptions);

    els.carousel.find('.carousel-play-pause').on({
      click: function () {
        var $carouselPlayPauseElement = $(this);
        var playText = $carouselPlayPauseElement.data('playLabel');
        var pauseText = $carouselPlayPauseElement.data('pauseLabel');
        if ($carouselPlayPauseElement.hasClass('play')) {
          els.slides.slick('slickPlay').slick('slickNext');
          $carouselPlayPauseElement
            .removeClass('play').addClass('pause')
            .attr('aria-label', pauseText);
        } else if ($carouselPlayPauseElement.hasClass('pause')) {
          els.slides.slick('slickPause');
          $carouselPlayPauseElement
            .removeClass('pause').addClass('play')
            .attr('aria-label', playText);
        };
      }
    });

    els.slides.on({
      beforeChange: function (e, slick, currentSlide, nextSlide) {
        var $currSlide = els.slides.find('.slide[data-slick-index="' + currentSlide.toString() + '"]');
        $currSlide.removeAttr('aria-live', null);
      },
      afterChange: function(e, slick, currentSlide, nextSlide) {
        var $currSlide = els.slides.find('.slide[data-slick-index="' + currentSlide.toString() + '"]');
        $currSlide.attr('aria-live', 'polite');
        els.carousel.find('.slide-count').text((currentSlide + 1) + '/' + slideCount);
      }
    });
  };

  return {
    init: function (el) {
      var $el = $(el);

      els = {
        component: $el,
        carousel: $el.find('.strip-component-slide-show-carousel'),
        slides: $el.find('.strip-component-slide-show-carousel .slides'),
      };

      initSlick();
    }
  };
};

$(function () {
  if ($('.strip-component.slide-show').length > 0) {
    $('.strip-component.slide-show').each(function (i, el) {
      var stripSlideShow = new DS.stripSlideShow();
      stripSlideShow.init(el);
    });
  }
});
